import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import GA4React from 'ga-4-react';
import configData from "./config.json";

const ga4react = new GA4React(configData.GA_TAG);


const app = (
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
)

ga4react.initialize()
  .then(() => configData.GA_TRACKING_BLOCKED = false)
  .catch(() => configData.GA_TRACKING_BLOCKED = true)
  // eslint-disable-next-line react/no-render-return-value
  .finally(() => ReactDOM.render(app, document.getElementById("root")));

  





