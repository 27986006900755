import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Step, StepLabel, Stepper } from "@mui/material";

import AlertDialog from "../widgets/AlertDialog";
import Spinner from "../widgets/Spinner";
import TextGeneratorStep0 from "./TextGeneratorStep0";
import TextGeneratorStep1 from "./TextGeneratorStep1";
import TextGeneratorStep2 from "./TextGeneratorStep2";
import TextGeneratorStep3 from "./TextGeneratorStep3";

import nlpApi from "../../api/nlp";
import { TextGeneratorContext, UserContext } from "../../Context";
import useApi from "../../hooks/useApi";

import "../../styles.css";
import { augmentErrorTryAgain } from "../../util/errorutil";

const steps = ["Topic/Title", "Sections", "Talking points", "Content"];

const testDBKeywords = true

export default function MultiStepTextGenerator() {
  const navigate = useNavigate();

  const [userContext, setUserContext] = useContext(UserContext);
  const [formContext, setFormContext] = useState(null);

  const getKeywords = useApi(nlpApi.getKeywords);
  const getDBKeywords = useApi(nlpApi.getDBKeywords);
  
  const [activeStep, setActiveStep] = React.useState(0);
  const [formState, setFormState] = React.useState({
    type: "blog",
    audience: "CMO",
    tone: "formal",
    titleKeyword: "",
    keywords: [],
    siteKeywords: [],
    topic: "",
    description: "",

    title: "",
    generatedTitles: [],
    sections: [],
    sectionPoints: [],
    text: "", //TODO - text format? editor block?
  });

  const [busy, _setBusy] = useState(false);
  const [dialogText, setDialogText] = useState({ title: "", text: "" });
  const [dialogOpen, setDialogOpen] = useState(false);
  const closeDialog = () => {
    if(!userContext?.selectedClient){
      navigate("/agencyClients");
    }
    setDialogOpen(false);
  };

  const setBusy = (flag, error) => {
    _setBusy(flag);
    if (error) {
      setDialogText({ title: "Error", text: error });
      setDialogOpen(true);
    }
  };
  const handleNext = () => {
    if (activeStep === steps.length - 1) return;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) return;
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const setSiteKeywords = (value) => {
    setFormContext({...formContext, siteKeywords: value})
  }
  const setInitialFormContext = () => {
    setFormContext({
      seoOptimized: false,
      seoTargetKeyword: null,
      type: "blog",
      audience: "CMO",
      tone: "formal",
      titleKeyword: "",
      keywords: [],
      siteKeywords: [],
      topic: "",
      description: "",
  
      title: "",
      generatedTitles: [],
      sections: [],
      sectionPoints: [],
      text: "",
      textBlocks: [],
    });
  }
  useEffect(() => {
    if (!userContext?.selectedClient) {
      setDialogText({
        title: "Error",
        text: "No Client Selected",
      });
      setDialogOpen(true);
    } else {
      setInitialFormContext();
      setBusy(true);
      if(testDBKeywords)
        getDBKeywords.request(userContext?.selectedClient, userContext?.selectedURL);
      else
        getKeywords.request(userContext?.selectedURL);
    }
  }, []);

  useEffect(() => {
    if (getKeywords.data) {
      setBusy(false);
      setSiteKeywords(getKeywords.data.data);
    }
  }, [getKeywords.data]);
  useEffect(() => {
    if (getKeywords.error && getKeywords.error !== "") {
      let errorText = augmentErrorTryAgain("Error getting keywords.", getKeywords.error)
      setBusy(false);
      setDialogText({ title: "Error", text: errorText });
      setDialogOpen(true);
    }
  }, [getKeywords.error]);

  useEffect(() => {
    if (getDBKeywords.data) {
      setBusy(false);
      setSiteKeywords(JSON.parse(getDBKeywords.data.data));
    }
  }, [getDBKeywords.data]);
  useEffect(() => {
    if (getDBKeywords.error && getDBKeywords.error !== "") {
      let errorText = augmentErrorTryAgain("Error getting keywords.", getDBKeywords.error)
      setBusy(false);
      setDialogText({ title: "Error", text: errorText });
      setDialogOpen(true);
    }
  }, [getDBKeywords.error]);

  return (
    <TextGeneratorContext.Provider value={[formContext, setFormContext]}>
      <Box sx={{ width: "100%" }}>
        <Stepper className="center50" activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === 0 ? (
          <TextGeneratorStep0
            handleBack={handleBack}
            handleNext={handleNext}
            busyCallback={setBusy}
          />
        ) : null}
        {activeStep === 1 ? (
          <TextGeneratorStep1
            handleBack={handleBack}
            handleNext={handleNext}
            busyCallback={setBusy}
          />
        ) : null}
        {activeStep === 2 ? (
          <TextGeneratorStep2
            handleBack={handleBack}
            handleNext={handleNext}
            busyCallback={setBusy}
          />
        ) : null}
        {activeStep === 3 ? (
          <TextGeneratorStep3
            handleBack={handleBack}
            handleNext={handleNext}
            busyCallback={setBusy}
          />
        ) : null}
      </Box>
      <AlertDialog
        dialogOpen={dialogOpen}
        title={dialogText.title}
        text={dialogText.text}
        callback={closeDialog}
      />

      <Spinner busy={busy} />
    </TextGeneratorContext.Provider>
  );
}
