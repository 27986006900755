import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";

import AlertDialog from "../widgets/AlertDialog";
import CustomTableFooter from "../widgets/CustomTableFooter";
import HelpTooltip from "../widgets/HelpTooltip";
import LengthTooltip from "../widgets/LengthTooltip";
import Spinner from "../widgets/Spinner";
import { StyledTableHead } from "../widgets/StyledWidgets";

import nlpApi from "../../api/nlp";
import gptApi from "../../api/gpt";
import gpt2Api from "../../api/gpt2";

import { UserContext } from "../../Context";
import useApi from "../../hooks/useApi";

import "../../styles.css";
import { augmentErrorTryAgain } from "../../util/errorutil";
import SEOWrapperSimple from "../text/ToolbarComponents/SEOWrapperSimple";

export default function Planner() {
  const navigate = useNavigate();

  const analyzeUrlsApi = useApi(nlpApi.analyzeUrls);
  const genPlannerIdeaApi = useApi(gpt2Api.genPlannerIdea);
  const serpUrlApi = useApi(nlpApi.serpUrls);

  const genSupportingKeywordsApi = useApi(gptApi.genSupportingKeywords);

  const [formContext, setFormContext] = useState(null);
  const [userContext, setUserContext] = useContext(UserContext);
  const [orderBy, setOrderBy] = useState("TS");
  const [sortOrder, setSortOrder] = useState("desc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const [siteKeywords, setSiteKeywords] = useState([]);
  const [supporting, setSupporting] = useState([]);
  const [serpKeywords, setSerpKeywords] = useState(null);
  const [serp, setSerp] = useState([]);
  const [ideas, setIdeas] = useState([]);
  const [selectedKeyword, _setSelectedKeyword] = useState(null);

  const getDBKeywordDetails = useApi(nlpApi.getDBKeywordDetails);
  const [busy, setBusy] = useState(false);
  const [dialogText, setDialogText] = useState({ title: "", text: "" });
  const [dialogOpen, setDialogOpen] = useState(false);
  const closeDialog = () => {
    if (!userContext?.selectedClient) {
      navigate("/agencyClients");
    }
    setDialogOpen(false);
  };

  useEffect(() => {
    if (!userContext?.selectedClient) {
      setDialogText({
        title: "Error",
        text: "No Client Selected",
      });
      setDialogOpen(true);
    } else {
      setBusy(true);
      getDBKeywordDetails.request(
        userContext?.selectedClient,
        userContext?.selectedURL
      );
    }
  }, []);

  const setSelectedKeyword = (val) => {
    _setSelectedKeyword(val);
    serpUrlApi.request(val.keyword);
    genSupportingKeywordsApi.request(val.keyword, userContext?.selectedClient);
  };
  const handleSEO = () => {
    setBusy(true);
    analyzeUrlsApi.request(serp.map((a) => a.url));
  };
  const handleIdeas = () => {
    setBusy(true);
    let reqObject = {
      type: "blog",
      keyword: selectedKeyword.keyword,
      keywords: supporting,
      client: userContext?.selectedClient,
    };
    genPlannerIdeaApi.request(reqObject);
  };

  useEffect(() => {
    console.log("selection", supporting);
  }, [supporting]);
  useEffect(() => {
    if (serpUrlApi.data) {
      setBusy(false);
      setSerp(serpUrlApi.data.data);
    }
  }, [serpUrlApi.data]);
  useEffect(() => {
    if (serpUrlApi.error && serpUrlApi.error !== "") {
      let errorText = augmentErrorTryAgain(
        "Error getting top sites.",
        serpUrlApi.error
      );
      setBusy(false);
      setDialogText({ title: "Error", text: errorText });
      setDialogOpen(true);
    }
  }, [serpUrlApi.error]);

  useEffect(() => {
    if (analyzeUrlsApi.data) {
      setBusy(false);
      setSerpKeywords(analyzeUrlsApi.data.data);
    }
  }, [analyzeUrlsApi.data]);
  useEffect(() => {
    if (analyzeUrlsApi.error && analyzeUrlsApi.error !== "") {
      let errorText = augmentErrorTryAgain(
        "Error analizing top sites.",
        analyzeUrlsApi.error
      );
      setBusy(false);
      setDialogText({ title: "Error", text: errorText });
      setDialogOpen(true);
    }
  }, [analyzeUrlsApi.error]);

  useEffect(() => {
    if (genSupportingKeywordsApi.data) {
      setBusy(false);
      setSupporting(genSupportingKeywordsApi.data.data);
    }
  }, [genSupportingKeywordsApi.data]);
  useEffect(() => {
    if (
      genSupportingKeywordsApi.error &&
      genSupportingKeywordsApi.error !== ""
    ) {
      let errorText = augmentErrorTryAgain(
        "Error getting additional keywords.",
        genSupportingKeywordsApi.error
      );
      setBusy(false);
      setDialogText({ title: "Error", text: errorText });
      setDialogOpen(true);
    }
  }, [genSupportingKeywordsApi.error]);

  useEffect(() => {
    if (getDBKeywordDetails.data) {
      setBusy(false);
      setSiteKeywords(JSON.parse(getDBKeywordDetails.data.data));
      console.log(JSON.parse(getDBKeywordDetails.data.data));
    }
  }, [getDBKeywordDetails.data]);
  useEffect(() => {
    if (getDBKeywordDetails.error && getDBKeywordDetails.error !== "") {
      let errorText = augmentErrorTryAgain(
        "Error getting keywords.",
        getDBKeywordDetails.error
      );
      setBusy(false);
      setDialogText({ title: "Error", text: errorText });
      setDialogOpen(true);
    }
  }, [getDBKeywordDetails.error]);

  useEffect(() => {
    if (genPlannerIdeaApi.data) {
      setBusy(false);
      console.log(JSON.parse(genPlannerIdeaApi.data.data));
      setIdeas(JSON.parse(genPlannerIdeaApi.data.data));
    }
  }, [genPlannerIdeaApi.data]);
  useEffect(() => {
    if (genPlannerIdeaApi.error && genPlannerIdeaApi.error !== "") {
      let errorText = augmentErrorTryAgain(
        "Error generating ideas",
        genPlannerIdeaApi.error
      );
      setBusy(false);
      setDialogText({ title: "Error", text: errorText });
      setDialogOpen(true);
    }
  }, [genPlannerIdeaApi.error]);

  useEffect(() => {
    if (siteKeywords.length > 0) {
      sortData(sortOrder);
    }
  }, [orderBy, sortOrder]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const sortData = (sortOrder) => {
    let tempData = [...siteKeywords];
    tempData.sort(function (a, b) {
      return sortOrder === "asc"
        ? a[orderBy] < b[orderBy]
          ? 1
          : -1
        : a[orderBy] > b[orderBy]
        ? 1
        : -1;
    });
    setSiteKeywords([...tempData]);
  };

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const handleDeleteUrl = (url) => {
    let tempSerp = serp;
    tempSerp = tempSerp.filter((item) => !(item.url === url));
    setSerp([...tempSerp]);
  };
  const handleDeleteSupporting = (entry) => {
    let tempSupporting = supporting;
    tempSupporting = tempSupporting.filter((item) => !(item === entry));
    setSupporting([...tempSupporting]);
  };
  const genSerpList = () => {
    return (
      <List dense={true}>
        {serp.map((entry, index) => {
          return (
            <ListItem
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeleteUrl(entry.url)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <a target="_blank" rel="noopener noreferrer" href={entry.url}>
                {entry.title}
              </a>
            </ListItem>
          );
        })}
      </List>
    );
  };
  const genSupportingList = (supporting, selectedKeyword) => {
    return (
      <List dense={true}>
        <ListItem>{selectedKeyword.keyword}</ListItem>
        {supporting.map((entry, index) => {
          return (
            <ListItem
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeleteSupporting(entry)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              {entry}
            </ListItem>
          );
        })}
      </List>
    );
  };

  const genKeywordsTable = (data, selectionCallback) => {
    return (
      <TableContainer component={Paper}>
        <Table size="small" aria-label="simple table">
          <StyledTableHead>
            <TableRow>
              <TableCell
                align="right"
                sortDirection={orderBy === "keyword" ? sortOrder : false}
              >
                <TableSortLabel
                  active={orderBy === "keyword"}
                  direction={orderBy === "keyword" ? sortOrder : "asc"}
                  onClick={createSortHandler("keyword")}
                >
                  Keyword
                </TableSortLabel>
              </TableCell>
              <TableCell></TableCell>
              <TableCell
                align="right"
                sortDirection={orderBy === "competition" ? sortOrder : false}
              >
                <TableSortLabel
                  active={orderBy === "competition"}
                  direction={orderBy === "competition" ? sortOrder : "asc"}
                  onClick={createSortHandler("competition")}
                >
                  Competition
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="right"
                sortDirection={orderBy === "ISP" ? sortOrder : false}
              >
                <TableSortLabel
                  active={orderBy === "searchVolume"}
                  direction={orderBy === "searchVolume" ? sortOrder : "asc"}
                  onClick={createSortHandler("searchVolume")}
                >
                  Search Volume
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((entry, index) => {
              return (
                <TableRow
                  onClick={() => selectionCallback(entry)}
                  key={"=sitekeywordstable" + entry.keyword + index}
                >
                  <TableCell component="th" scope="row">
                    <LengthTooltip
                      text={entry.keyword ? entry.keyword : entry}
                      length={20}
                    />
                  </TableCell>
                  <TableCell
                    style={{ padding: "0px" }}
                    component="th"
                    scope="row"
                  >
                    {entry.priority ? (
                      <HelpTooltip text="Ranked but no clicks" size="small" />
                    ) : (
                      <HelpTooltip
                        text="Recommended by ConversionAI"
                        size="small"
                      />
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    {entry.competition}
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    {entry.searchVolume}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <CustomTableFooter
            colSpan={8}
            data={data}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Table>
      </TableContainer>
    );
  };

  const genIdeasTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table size="small" aria-label="simple table">
          <StyledTableHead>
            <TableRow>
              <TableCell align="left" sx={{ width: 1 / 3 }}>
                Title
              </TableCell>
              <TableCell align="left" sx={{ width: 2 / 3 }}>
                Description
              </TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {ideas.map((entry, index) => {
              return (
                <TableRow key={"=plannerideastable" + entry.title + index}>
                  <TableCell component="th" scope="row">
                    {entry.title}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {entry.description}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid container item xs={8}>
          <Grid item xs={6}>
            <Grid item>
              Suggested Keywords
              {genKeywordsTable(siteKeywords, setSelectedKeyword)}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item>
              Supporting Keywords and Sentences
              {selectedKeyword
                ? genSupportingList(supporting, selectedKeyword)
                : null}
              {selectedKeyword ? (
                <Button className="center90" onClick={() => handleIdeas()}>Ideas</Button>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12}>
          <Grid item>
            Ideas
            {ideas.length > 0 ? genIdeasTable() : null}
          </Grid>
        </Grid>

        </Grid>
        <Grid item xs={4}>
          <Grid container item>
            Top Search Results
            {serp.length > 0 ? genSerpList() : null}
            {serp.length > 0 ? (
              <div style={{width:"100%"}}>
              <Button className="center90" onClick={() => handleSEO()}>Scan</Button>
              </div>
            ) : null}
            {serpKeywords ? (
              <SEOWrapperSimple serpKeywords={serpKeywords} />
            ) : null}
          </Grid>
        </Grid>
      </Grid>

      <AlertDialog
        dialogOpen={dialogOpen}
        title={dialogText.title}
        text={dialogText.text}
        callback={closeDialog}
      />

      <Spinner busy={busy} />
    </div>
  );
}
