import React, { useEffect, useState, lazy, Suspense } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

import { Route, Routes, useNavigate, useLocation } from "react-router-dom";

import { useGA4React } from "ga-4-react";

import AppTitleBar from "./components/AppTitleBar";
import Login from "./components/login/Login";

import GlobalError1 from "./components/widgets/GlobalError1";

import { UserContext } from "./Context";

import TokenStorage from "./api/TokenStorage";
import clientApi from "./api/clients";
import useApi from "./hooks/useApi";
import configData from "./config.json";

import TextEditorWrapper from "./components/text/TextComponents/TextEditorWrapper";
import MultiStepTextGenerator from "./components/text/MultiStepTextGenerator";
import { blocks } from "./TestTextBlocks";
import Planner from "./components/planner/Planner";
import QueryAnalysis from "./components/planner/QueryAnalysis";
import GoogleAnalytics from "./components/planner/GoogleAnalytics";
import ClientVoices from "./components/client/ClientVoices";
import ClientProfile from "./components/client/ClientProfile";
import RephraseTool from "./components/text/RephraseTool";
import KeywordsAndProjects from "./components/planner/KeywordsAndProjects";
import Profiles from "./components/prospects/Prospects";
const seoTitleDesc = { "title": "Is ChatGPT useful in Marketing?", "description": "GPT-powered technology is a game-changer for streamlining marketing and sales processes. ChatGPT, in particular, can offer numerous benefits to both marketing and sales teams." }

const Registration = lazy(() =>
  import("./components/registration/Registration")
);
const AgencyClients = lazy(() => import("./components/agency/AgencyClients"));
const AnalyticsHelp = lazy(() => import("./components/help/AnalyticsHelp"));
const NotImplemented = lazy(() => import("./components/help/NotImplemented"));
const PrivacyHelp = lazy(() => import("./components/help/PrivacyHelp"));
const SetupHelp = lazy(() => import("./components/help/SetupHelp"));
const ShowSID = lazy(() => import("./components/help/ShowSID"));
const ImplementationNotes = lazy(() =>
  import("./components/help/ImplementationNotes")
);
const CustomerCommunication = lazy(() =>
  import("./components/campaigns/CustomerCommunication")
);
const CustomerCommunicationOrig = lazy(() =>
  import("./components/campaigns/CustomerCommunicationOrig")
);
const ViewCampaigns = lazy(() =>
  import("./components/campaigns/ViewCampaigns")
);
const ViewCustomerCommunication = lazy(() =>
  import("./components/campaigns/ViewCustomerCommunication")
);
const AccessAnalytics = lazy(() =>
  import("./components/analytics/AccessAnalytics")
);
const CampaignAnalytics = lazy(() =>
  import("./components/analytics/CampaignAnalytics")
);
const CommunicationsByAccount = lazy(() =>
  import("./components/analytics/CommunicationsByAccount")
);
const StatsByCommunications = lazy(() =>
  import("./components/analytics/StatsByCommunications")
);
const StatsByCommunicationsNoVisitID = lazy(() =>
  import("./components/analytics/StatsByCommunicationsNoVisitID")
);
const ManualContactEntry = lazy(() =>
  import("./components/campaigns/ManualContactEntry")
);
const NewCampaign = lazy(() => import("./components/campaigns/NewCampaign"));
const UploadContactsCSV = lazy(() =>
  import("./components/campaigns/UploadContactsCSV")
);
const NotificationsAnalytics = lazy(() =>
  import("./components/notifications/Analytics")
);

const CommunicationTemplates = lazy(() =>
  import("./components/templates/CommunicationTemplates")
);
const Report = lazy(() => import("./components/report/Report"));
const EmailGenerator = lazy(() => import("./components/text/EmailGenerator"));
const TextGenerator = lazy(() => import("./components/text/TextGenerator"));
const GeneratorBySample = lazy(() =>
  import("./components/text/GeneratorBySample")
);
const GeneratorByTopic = lazy(() =>
  import("./components/text/GeneratorByTopic")
);

const IFrames = lazy(() => import("./components/widgets/IFrames"));




const tokenStorageService = TokenStorage.getService();

window.onerror = function(msg, url, lineNo, columnNo, error) { 
  console.log('error', msg, url, lineNo, columnNo, error)
  console.log(tokenStorageService.getUserContext())

  window.location.href = '/globalError';
}



export default function App() {
  const navigate = useNavigate();
  const getSitesApi = useApi(clientApi.getSites);
  //TEMP - PATCH
  const patchAnalyticsApi = useApi(clientApi.patchAnalytics);

  useEffect(() => {
    document.title = "Asperios B2B";
  });
  const [sessionid, setSessionid] = useState(
    tokenStorageService.getSessionId()
  );
  const [page, setPage] = useState(null);
  const [userContext, setUserContext] = useState(null);

  const ga4 = useGA4React();
  let location = useLocation();

  React.useEffect(() => {
    if (!configData.GA_TRACKING_BLOCKED && !!ga4) {
      console.log(location);
      ga4.pageview(location.path, location.path, "page");
    }
  }, [location]);

  useEffect(() => {
    setPage({ target: "/campaignAnalytics" });
  }, []);
  function loginCallback(args) {
    console.log(args);
    if (args.login) {
      let client = tokenStorageService.getClient();
      if (client) {
        setSessionid(tokenStorageService.getSessionId());
        let agency = tokenStorageService.getAgency();
        if (agency) {
          console.log(agency);
          let tempUserContext = {
            manager: tokenStorageService.getManager(),
            agency: tokenStorageService.getAgency(),
            selectedClient: null,
            selectedClientName: null,
            selectedSID: null,
            selectedURL: null,
            selectedUserName: null,
            accessToken: tokenStorageService.getAccessToken(),
          };
          setUserContext(tempUserContext);
          tokenStorageService.setUserContext(tempUserContext);
        } else {
          getSitesApi.request(client);
        }
      }
    }
  }

  useEffect(() => {
    if (getSitesApi.data) {
      let client = Object.keys(getSitesApi.data.data)[0];
      let name = getSitesApi.data.data[client][0]["name"];
      let sid = getSitesApi.data.data[client][0]["sid"];
      let baseURL = getSitesApi.data.data[client][0]["baseURL"];

      let tempUserContext = {
        manager: tokenStorageService.getManager(),
        agency: null,
        selectedClient: client,
        selectedClientName: name,
        selectedSID: sid,
        selectedURL: baseURL,
        selectedUserName: tokenStorageService.getUserName(),
        accessToken: tokenStorageService.getAccessToken(),
      };
      setUserContext(tempUserContext);
      tokenStorageService.setUserContext(tempUserContext);
    }
  }, [getSitesApi.data]);
  useEffect(() => {
    if (getSitesApi.error && getSitesApi.error !== "") {
      console.log(getSitesApi.error);
      alert("Error getting sites");
    }
  }, [getSitesApi.error]);

  useEffect(() => {
    if (!userContext) {
      let tempUserContext = tokenStorageService.getUserContext();
      if (tempUserContext) setUserContext(tempUserContext);
    }
  }, [userContext]);

  function registrationCallback(args) {
    navigate("/");
    setSessionid(null);
  }

  const logoutCallback = () => {
    navigate("/");
    setSessionid(null);
  };
  window.logoutCallback = logoutCallback;

  function menuCallback(args) {
    console.log(args);

    //TEMP - PATCH
    if (args.pathname === "/patchAnalytics") patchAnalyticsApi.request();
    else if (args.pathname) navigate(args.pathname);
  }

  if (window.location.search.length > 0) {
    let query = window.location.search;
    if (query.startsWith("?rsvp=")) {
      let token = query.slice("?rsvp=".length);
      return (
        <Registration
          selfRegistration={false}
          token={token}
          registrationCallback={registrationCallback}
        />
      );
    }
  }

    function routerMenu() {
      return (
        <Routes>
          <Route path="/globalError" exact element={
            <GlobalError1 />} />


          {userContext?.agency ? (
            <Route path="/" element={<AgencyClients />} />
          ) : (
            <Route path="/" element={<MultiStepTextGenerator />} />
          )}
          <Route path="/selfRegistration" exact element={
            <Registration
              selfRegistration={true}
              agencyRegistration={false}
              registrationCallback={registrationCallback} />
          } />
          <Route path="/agencyRegistration" exact element={
            <Registration
              selfRegistration={true}
              agencyRegistration={true}
              registrationCallback={registrationCallback}
            />} />
          <Route path="/agencyClients" exact element={
            <AgencyClients />} />

          <Route path="/clientProfile" exact element={
            <ClientProfile />} />
          <Route path="/clientVoices" exact element={
            <ClientVoices />} />
          <Route path="/rephraseTool" exact element={
            <RephraseTool />} />
          <Route path="/notificationsAnalytics" exact element={
            <NotificationsAnalytics />} />
          <Route path="/customerCommunication" exact element={
            <CustomerCommunication />} />
          <Route path="/customerCommunicationOrig" exact element={
            <CustomerCommunicationOrig />} />
          <Route path="/viewCustomerCommunication" exact element={
            <ViewCustomerCommunication />} />
          <Route path="/viewSalesCampaigns" exact element={
            <ViewCampaigns />} />
          <Route path="/uploadSalesContacts" exact element={
            <UploadContactsCSV />} />
          <Route path="/createSalesCampaign" exact element={
            <NewCampaign />} />
          <Route path="/createSalesContact" exact element={
            <ManualContactEntry />} />
          <Route path="/accessAnalytics" exact element={
            <AccessAnalytics />} />
          <Route path="/marketingAnalytics" exact element={
            <CampaignAnalytics />} />
          <Route path="/salesAnalytics" exact element={
            <CampaignAnalytics />} />

          <Route path="/plannerv2" exact element={
            <KeywordsAndProjects />} />
          <Route path="/planner" exact element={
            <Planner />} />
          <Route path="/plannertest1" exact element={
            <QueryAnalysis />} />
          <Route path="/plannertest2" exact element={
            <GoogleAnalytics />} />
          <Route path="/affinityProfiles" exact element={
            <Profiles />} />
          <Route path="/editor" exact element={
            <TextEditorWrapper id={"demo-editor"} context={{ client: userContext?.selectedClient }} seo={true} seoTitleDesc={seoTitleDesc} seoTargetKeyword={"ChatGPT in Marketing and Sales"} docType={"website"} model={"FULL"} text={blocks} />
          } />
          <Route path="/smalleditor" exact element={
            <TextEditorWrapper
              id={"demo-editor"}
              context={{ client: userContext?.selectedClient }}
              seo={false}
              model={"HEADING"}
              text={[
                {
                  id: "sheNwCUP5A",
                  type: "header",
                  data: {
                    text: "Using ChatGPT to get more out of Marketing and Sales",
                    level: 2,
                  },
                },
              ]}
            />} />
          <Route path="/multi" exact element={
            <MultiStepTextGenerator />} />

          <Route path="/emailTest" exact element={
            <EmailGenerator to={"Tom Corr"} toCompany={"AIP"} keywords={["test 1", "test 2", "test 3"]} />
          } />

          <Route path="/emailGenerator" exact element={
            <EmailGenerator />} />
          <Route path="/textGenerator" exact element={
            <TextGenerator />} />
          <Route path="/genByTopic" exact element={
            <GeneratorByTopic />} />
          <Route path="/genBySample" exact element={
            <GeneratorBySample />} />

          <Route path="/communicationsByAccount" exact element={
            <CommunicationsByAccount />} />
          <Route path="/oldStatsByCommunications" exact element={
            <StatsByCommunicationsNoVisitID />} />
          <Route path="/statsByCommunications" exact element={
            <StatsByCommunications />} />
          <Route path="/communicationTemplates" exact element={
            <CommunicationTemplates />} />

          <Route path="/webSiteReport" exact element={
            <Report />} />

          <Route path="/marketing" exact element={
            <NotImplemented />} />
          <Route path="/marketingOptimization" exact element={
            <IFrames
              url={configData.marketingOptimizationBaseURL + '?client=' + userContext.selectedClientName}
              params={''}
            />} />

          <Route path="/notificationsAnalytics" exact element={
            <NotificationsAnalytics />} />

          <Route path="/analyticsHelp" exact element={
            <AnalyticsHelp />} />
          <Route path="/privacyHelp" exact element={
            <PrivacyHelp />} />
          <Route path="/setupHelp" exact element={
            <SetupHelp />} />
          <Route path="/showSID" exact element={
            <ShowSID />} />
          <Route path="/implementation" exact element={
            <ImplementationNotes />} />
          <Route path="/userGuide" exact element={
            <NotImplemented />} />
        </Routes>
      );
    }

  const genPage = () => {
    return sessionid && userContext ? (
      <div>
        <ThemeProvider theme={theme}>
          <UserContext.Provider value={[userContext, setUserContext]}>
            <AppTitleBar
              menuCallback={menuCallback}
              logoutCallback={logoutCallback}
            />
            <div className="content-container">{routerMenu()}</div>
          </UserContext.Provider>
        </ThemeProvider>
      </div>
    ) : (
      <Login loginCallback={loginCallback} />
    );
  };

  return (
    <div className="App">
        <Suspense fallback={<h1>Loading...</h1>}>
          {window.location.pathname === "/selfRegistration" ||
            window.location.pathname === "/agencyRegistration" ? (
            <ThemeProvider theme={theme}>
              <Registration
                agencyRegistration={
                  window.location.pathname === "/agencyRegistration"
                }
                selfRegistration={true}
                registrationCallback={registrationCallback}
              />
            </ThemeProvider>
          ) : (
            genPage()
          )}
        </Suspense>

    </div>
  );
}
