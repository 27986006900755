export const AllCountries = {
   "AF": {
      "Name": "Afghanistan"
   },
   "AX": {
      "Name": "Ã…land Islands"
   },
   "AL": {
      "Name": "Albania"
   },
   "DZ": {
      "Name": "Algeria"
   },
   "AS": {
      "Name": "American Samoa"
   },
   "AD": {
      "Name": "Andorra"
   },
   "AO": {
      "Name": "Angola"
   },
   "AI": {
      "Name": "Anguilla"
   },
   "AQ": {
      "Name": "Antarctica"
   },
   "AG": {
      "Name": "Antigua and Barbuda"
   },
   "AR": {
      "Name": "Argentina"
   },
   "AM": {
      "Name": "Armenia"
   },
   "AW": {
      "Name": "Aruba"
   },
   "AU": {
      "Name": "Australia"
   },
   "AT": {
      "Name": "Austria"
   },
   "AZ": {
      "Name": "Azerbaijan"
   },
   "BS": {
      "Name": "Bahamas"
   },
   "BH": {
      "Name": "Bahrain"
   },
   "BD": {
      "Name": "Bangladesh"
   },
   "BB": {
      "Name": "Barbados"
   },
   "BY": {
      "Name": "Belarus"
   },
   "BE": {
      "Name": "Belgium"
   },
   "BZ": {
      "Name": "Belize"
   },
   "BJ": {
      "Name": "Benin"
   },
   "BM": {
      "Name": "Bermuda"
   },
   "BT": {
      "Name": "Bhutan"
   },
   "BO": {
      "Name": "Bolivia, Plurinational State of"
   },
   "BQ": {
      "Name": "Bonaire, Sint Eustatius and Saba"
   },
   "BA": {
      "Name": "Bosnia and Herzegovina"
   },
   "BW": {
      "Name": "Botswana"
   },
   "BV": {
      "Name": "Bouvet Island"
   },
   "BR": {
      "Name": "Brazil"
   },
   "IO": {
      "Name": "British Indian Ocean Territory"
   },
   "BN": {
      "Name": "Brunei Darussalam"
   },
   "BG": {
      "Name": "Bulgaria"
   },
   "BF": {
      "Name": "Burkina Faso"
   },
   "BI": {
      "Name": "Burundi"
   },
   "KH": {
      "Name": "Cambodia"
   },
   "CM": {
      "Name": "Cameroon"
   },
   "CA": {
      "Name": "Canada"
   },
   "CV": {
      "Name": "Cape Verde"
   },
   "KY": {
      "Name": "Cayman Islands"
   },
   "CF": {
      "Name": "Central African Republic"
   },
   "TD": {
      "Name": "Chad"
   },
   "CL": {
      "Name": "Chile"
   },
   "CN": {
      "Name": "China"
   },
   "CX": {
      "Name": "Christmas Island"
   },
   "CC": {
      "Name": "Cocos (Keeling) Islands"
   },
   "CO": {
      "Name": "Colombia"
   },
   "KM": {
      "Name": "Comoros"
   },
   "CG": {
      "Name": "Congo"
   },
   "CD": {
      "Name": "Congo, the Democratic Republic of the"
   },
   "CK": {
      "Name": "Cook Islands"
   },
   "CR": {
      "Name": "Costa Rica"
   },
   "CI": {
      "Name": "CÃ´te d'Ivoire"
   },
   "HR": {
      "Name": "Croatia"
   },
   "CU": {
      "Name": "Cuba"
   },
   "CW": {
      "Name": "CuraÃ§ao"
   },
   "CY": {
      "Name": "Cyprus"
   },
   "CZ": {
      "Name": "Czech Republic"
   },
   "DK": {
      "Name": "Denmark"
   },
   "DJ": {
      "Name": "Djibouti"
   },
   "DM": {
      "Name": "Dominica"
   },
   "DO": {
      "Name": "Dominican Republic"
   },
   "EC": {
      "Name": "Ecuador"
   },
   "EG": {
      "Name": "Egypt"
   },
   "SV": {
      "Name": "El Salvador"
   },
   "GQ": {
      "Name": "Equatorial Guinea"
   },
   "ER": {
      "Name": "Eritrea"
   },
   "EE": {
      "Name": "Estonia"
   },
   "ET": {
      "Name": "Ethiopia"
   },
   "FK": {
      "Name": "Falkland Islands (Malvinas)"
   },
   "FO": {
      "Name": "Faroe Islands"
   },
   "FJ": {
      "Name": "Fiji"
   },
   "FI": {
      "Name": "Finland"
   },
   "FR": {
      "Name": "France"
   },
   "GF": {
      "Name": "French Guiana"
   },
   "PF": {
      "Name": "French Polynesia"
   },
   "TF": {
      "Name": "French Southern Territories"
   },
   "GA": {
      "Name": "Gabon"
   },
   "GM": {
      "Name": "Gambia"
   },
   "GE": {
      "Name": "Georgia"
   },
   "DE": {
      "Name": "Germany"
   },
   "GH": {
      "Name": "Ghana"
   },
   "GI": {
      "Name": "Gibraltar"
   },
   "GR": {
      "Name": "Greece"
   },
   "GL": {
      "Name": "Greenland"
   },
   "GD": {
      "Name": "Grenada"
   },
   "GP": {
      "Name": "Guadeloupe"
   },
   "GU": {
      "Name": "Guam"
   },
   "GT": {
      "Name": "Guatemala"
   },
   "GG": {
      "Name": "Guernsey"
   },
   "GN": {
      "Name": "Guinea"
   },
   "GW": {
      "Name": "Guinea-Bissau"
   },
   "GY": {
      "Name": "Guyana"
   },
   "HT": {
      "Name": "Haiti"
   },
   "HM": {
      "Name": "Heard Island and McDonald Islands"
   },
   "VA": {
      "Name": "Holy See (Vatican City State)"
   },
   "HN": {
      "Name": "Honduras"
   },
   "HK": {
      "Name": "Hong Kong"
   },
   "HU": {
      "Name": "Hungary"
   },
   "IS": {
      "Name": "Iceland"
   },
   "IN": {
      "Name": "India"
   },
   "ID": {
      "Name": "Indonesia"
   },
   "IR": {
      "Name": "Iran, Islamic Republic of"
   },
   "IQ": {
      "Name": "Iraq"
   },
   "IE": {
      "Name": "Ireland"
   },
   "IM": {
      "Name": "Isle of Man"
   },
   "IL": {
      "Name": "Israel"
   },
   "IT": {
      "Name": "Italy"
   },
   "JM": {
      "Name": "Jamaica"
   },
   "JP": {
      "Name": "Japan"
   },
   "JE": {
      "Name": "Jersey"
   },
   "JO": {
      "Name": "Jordan"
   },
   "KZ": {
      "Name": "Kazakhstan"
   },
   "KE": {
      "Name": "Kenya"
   },
   "KI": {
      "Name": "Kiribati"
   },
   "KP": {
      "Name": "Korea, Democratic People's Republic of"
   },
   "KR": {
      "Name": "Korea, Republic of"
   },
   "KW": {
      "Name": "Kuwait"
   },
   "KG": {
      "Name": "Kyrgyzstan"
   },
   "LA": {
      "Name": "Lao People's Democratic Republic"
   },
   "LV": {
      "Name": "Latvia"
   },
   "LB": {
      "Name": "Lebanon"
   },
   "LS": {
      "Name": "Lesotho"
   },
   "LR": {
      "Name": "Liberia"
   },
   "LY": {
      "Name": "Libya"
   },
   "LI": {
      "Name": "Liechtenstein"
   },
   "LT": {
      "Name": "Lithuania"
   },
   "LU": {
      "Name": "Luxembourg"
   },
   "MO": {
      "Name": "Macao"
   },
   "MK": {
      "Name": "Macedonia, the Former Yugoslav Republic of"
   },
   "MG": {
      "Name": "Madagascar"
   },
   "MW": {
      "Name": "Malawi"
   },
   "MY": {
      "Name": "Malaysia"
   },
   "MV": {
      "Name": "Maldives"
   },
   "ML": {
      "Name": "Mali"
   },
   "MT": {
      "Name": "Malta"
   },
   "MH": {
      "Name": "Marshall Islands"
   },
   "MQ": {
      "Name": "Martinique"
   },
   "MR": {
      "Name": "Mauritania"
   },
   "MU": {
      "Name": "Mauritius"
   },
   "YT": {
      "Name": "Mayotte"
   },
   "MX": {
      "Name": "Mexico"
   },
   "FM": {
      "Name": "Micronesia, Federated States of"
   },
   "MD": {
      "Name": "Moldova, Republic of"
   },
   "MC": {
      "Name": "Monaco"
   },
   "MN": {
      "Name": "Mongolia"
   },
   "ME": {
      "Name": "Montenegro"
   },
   "MS": {
      "Name": "Montserrat"
   },
   "MA": {
      "Name": "Morocco"
   },
   "MZ": {
      "Name": "Mozambique"
   },
   "MM": {
      "Name": "Myanmar"
   },
   "NA": {
      "Name": "Namibia"
   },
   "NR": {
      "Name": "Nauru"
   },
   "NP": {
      "Name": "Nepal"
   },
   "NL": {
      "Name": "Netherlands"
   },
   "NC": {
      "Name": "New Caledonia"
   },
   "NZ": {
      "Name": "New Zealand"
   },
   "NI": {
      "Name": "Nicaragua"
   },
   "NE": {
      "Name": "Niger"
   },
   "NG": {
      "Name": "Nigeria"
   },
   "NU": {
      "Name": "Niue"
   },
   "NF": {
      "Name": "Norfolk Island"
   },
   "MP": {
      "Name": "Northern Mariana Islands"
   },
   "NO": {
      "Name": "Norway"
   },
   "OM": {
      "Name": "Oman"
   },
   "PK": {
      "Name": "Pakistan"
   },
   "PW": {
      "Name": "Palau"
   },
   "PS": {
      "Name": "Palestine, State of"
   },
   "PA": {
      "Name": "Panama"
   },
   "PG": {
      "Name": "Papua New Guinea"
   },
   "PY": {
      "Name": "Paraguay"
   },
   "PE": {
      "Name": "Peru"
   },
   "PH": {
      "Name": "Philippines"
   },
   "PN": {
      "Name": "Pitcairn"
   },
   "PL": {
      "Name": "Poland"
   },
   "PT": {
      "Name": "Portugal"
   },
   "PR": {
      "Name": "Puerto Rico"
   },
   "QA": {
      "Name": "Qatar"
   },
   "RE": {
      "Name": "RÃ©union"
   },
   "RO": {
      "Name": "Romania"
   },
   "RU": {
      "Name": "Russian Federation"
   },
   "RW": {
      "Name": "Rwanda"
   },
   "BL": {
      "Name": "Saint BarthÃ©lemy"
   },
   "SH": {
      "Name": "Saint Helena, Ascension and Tristan da Cunha"
   },
   "KN": {
      "Name": "Saint Kitts and Nevis"
   },
   "LC": {
      "Name": "Saint Lucia"
   },
   "MF": {
      "Name": "Saint Martin (French part)"
   },
   "PM": {
      "Name": "Saint Pierre and Miquelon"
   },
   "VC": {
      "Name": "Saint Vincent and the Grenadines"
   },
   "WS": {
      "Name": "Samoa"
   },
   "SM": {
      "Name": "San Marino"
   },
   "ST": {
      "Name": "Sao Tome and Principe"
   },
   "SA": {
      "Name": "Saudi Arabia"
   },
   "SN": {
      "Name": "Senegal"
   },
   "RS": {
      "Name": "Serbia"
   },
   "SC": {
      "Name": "Seychelles"
   },
   "SL": {
      "Name": "Sierra Leone"
   },
   "SG": {
      "Name": "Singapore"
   },
   "SX": {
      "Name": "Sint Maarten (Dutch part)"
   },
   "SK": {
      "Name": "Slovakia"
   },
   "SI": {
      "Name": "Slovenia"
   },
   "SB": {
      "Name": "Solomon Islands"
   },
   "SO": {
      "Name": "Somalia"
   },
   "ZA": {
      "Name": "South Africa"
   },
   "GS": {
      "Name": "South Georgia and the South Sandwich Islands"
   },
   "SS": {
      "Name": "South Sudan"
   },
   "ES": {
      "Name": "Spain"
   },
   "LK": {
      "Name": "Sri Lanka"
   },
   "SD": {
      "Name": "Sudan"
   },
   "SR": {
      "Name": "Suriname"
   },
   "SJ": {
      "Name": "Svalbard and Jan Mayen"
   },
   "SZ": {
      "Name": "Swaziland"
   },
   "SE": {
      "Name": "Sweden"
   },
   "CH": {
      "Name": "Switzerland"
   },
   "SY": {
      "Name": "Syrian Arab Republic"
   },
   "TW": {
      "Name": "Taiwan, Province of China"
   },
   "TJ": {
      "Name": "Tajikistan"
   },
   "TZ": {
      "Name": "Tanzania, United Republic of"
   },
   "TH": {
      "Name": "Thailand"
   },
   "TL": {
      "Name": "Timor-Leste"
   },
   "TG": {
      "Name": "Togo"
   },
   "TK": {
      "Name": "Tokelau"
   },
   "TO": {
      "Name": "Tonga"
   },
   "TT": {
      "Name": "Trinidad and Tobago"
   },
   "TN": {
      "Name": "Tunisia"
   },
   "TR": {
      "Name": "Turkey"
   },
   "TM": {
      "Name": "Turkmenistan"
   },
   "TC": {
      "Name": "Turks and Caicos Islands"
   },
   "TV": {
      "Name": "Tuvalu"
   },
   "UG": {
      "Name": "Uganda"
   },
   "UA": {
      "Name": "Ukraine"
   },
   "AE": {
      "Name": "United Arab Emirates"
   },
   "GB": {
      "Name": "United Kingdom"
   },
   "US": {
      "Name": "United States"
   },
   "UM": {
      "Name": "United States Minor Outlying Islands"
   },
   "UY": {
      "Name": "Uruguay"
   },
   "UZ": {
      "Name": "Uzbekistan"
   },
   "VU": {
      "Name": "Vanuatu"
   },
   "VE": {
      "Name": "Venezuela, Bolivarian Republic of"
   },
   "VN": {
      "Name": "Viet Nam"
   },
   "VG": {
      "Name": "Virgin Islands, British"
   },
   "VI": {
      "Name": "Virgin Islands, U.S."
   },
   "WF": {
      "Name": "Wallis and Futuna"
   },
   "EH": {
      "Name": "Western Sahara"
   },
   "YE": {
      "Name": "Yemen"
   },
   "ZM": {
      "Name": "Zambia"
   },
   "ZW": {
      "Name": "Zimbabwe"
   }
}