import { nlpApiInstance } from "./NLPApiInstance";


const getKeywords = (url) =>
  nlpApiInstance.post("/getkeywords", { url: url });

const getDBKeywords = (client, url) =>
  nlpApiInstance.post("/getdbkeywords", { client: client, url: url});
const getDBKeywordDetails = (client, url) =>
  nlpApiInstance.post("/getdbkeyworddetails", { client: client, url: url});


const emotion = (text, keyword) =>
  nlpApiInstance.post("/emotion", {
    text: text,
    keyword: keyword,
  });
const seo = (html) => nlpApiInstance.post("/seo", { text: html });

//Get top google search hits for keyword
const serpUrls = (text) => nlpApiInstance.get("/serpUrls/" + text);

const serptest = (text) =>
  nlpApiInstance.post("/serptest", {
    text: text,
  });

//Used to prime the cache, doesn't wait for result
const serp = (text) => nlpApiInstance.get("/serp/" + text);
//Similar, but for list of urls
const analyzeUrls = (urls) => nlpApiInstance.post("/analyzeUrls", { urls: urls });

//Combines the two above in a single call
const serpSeo = (text, keyword) =>
  nlpApiInstance.post("/serp_seo", {
    text: text,
    keyword: keyword,
  });

  const getConversionKeywords = (client) => nlpApiInstance.get("/getconversionkeywords/" + client);
  const getProjects = (client) => nlpApiInstance.get("/getprojects/" + client);

  const getProfileByClient = (client) => nlpApiInstance.get("/getprofilebyg/" + client);
  const getProfileKeywords = (client) => nlpApiInstance.get("/getprofilekeywords/" + client);

  const words = (text) =>
  nlpApiInstance.post("/words", {
    text: text,
  });

const expose = {
    analyzeUrls,
    emotion,
    getKeywords,
    getConversionKeywords,
    getDBKeywords,
    getDBKeywordDetails,
    getProfileByClient,
    getProfileKeywords,
    getProjects,
    seo,
    serp,
    serptest,
    serpSeo,
    serpUrls,
    words,
  };
  export default expose;
  