
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Paper,
    Radio,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableSortLabel,
    Tooltip,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";

import AlertDialog from "../widgets/AlertDialog";
import CustomTableFooter from "../widgets/CustomTableFooter";
import HelpTooltip from "../widgets/HelpTooltip";
import LengthTooltip from "../widgets/LengthTooltip";
import Spinner from "../widgets/Spinner";
import { StyledTableHead } from "../widgets/StyledWidgets";

import { AllCountries } from "../../data/countries";

import nlpApi from "../../api/nlp";
import gpt2Api from "../../api/gpt2";

import { UserContext } from "../../Context";
import useApi from "../../hooks/useApi";

import "../../styles.css";
import { augmentErrorTryAgain } from "../../util/errorutil";
import { emptyObject } from "../../util/util";
import Item from "../widgets/Item";

export default function KeywordsAndProjects() {
    const navigate = useNavigate();
    const [userContext, setUserContext] = useContext(UserContext);

    const analyzeUrlsApi = useApi(nlpApi.analyzeUrls);
    const getConversionKeywordsApi = useApi(nlpApi.getConversionKeywords);
    const getProjectsApi = useApi(nlpApi.getProjects);

    const genPlannerIdeaApi = useApi(gpt2Api.genPlannerIdea);
    const serpUrlApi = useApi(nlpApi.serpUrls);

    const [selectedKeyword, setSelectedKeyword] = useState(null);
    const [selectedCountry, _setSelectedCountry] = useState('US');
    const [conversionKeywords, setConversionKeywords] = useState([]);
    const [projects, setProjects] = useState([]);
    const [countries, setCountries] = useState(['US']);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [selectedRow, setSelectedRow] = useState(null);

    const getDBKeywordDetails = useApi(nlpApi.getDBKeywordDetails);
    const [busy, setBusy] = useState(false);
    const [dialogText, setDialogText] = useState({ title: "", text: "" });
    const [dialogOpen, setDialogOpen] = useState(false);
    const closeDialog = () => {
        if (!userContext?.selectedClient) {
            navigate("/agencyClients");
        }
        setDialogOpen(false);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const setSelectedCountry = (val) => {
        setSelectedKeyword(null)
        _setSelectedCountry(val)
    }
    useEffect(() => {
        if (!userContext?.selectedClient) {
            setDialogText({
                title: "Error",
                text: "No Client Selected",
            });
            setDialogOpen(true);
        } else {
            setBusy(true);
            getProjectsApi.request(userContext?.selectedClient);
            getConversionKeywordsApi.request(userContext?.selectedClient);
        }
    }, []);

    useEffect(() => {
        if (getConversionKeywordsApi.data) {
            setBusy(false)
            setConversionKeywords(getConversionKeywordsApi.data.data)
            let tempCountries = Object.keys(getConversionKeywordsApi.data.data)
            setCountries(tempCountries)
            if (tempCountries.length > 0)
                setSelectedCountry(tempCountries[0])
        }
    }, [getConversionKeywordsApi.data]);
    useEffect(() => {
        if (getConversionKeywordsApi.error && getConversionKeywordsApi.error !== "") {
            console.log(getConversionKeywordsApi.error);
            setDialogText({
                title: "Error",
                text: "Error retrieving keywords",
            });
            setDialogOpen(true);
        }
    }, [getConversionKeywordsApi.error]);
    useEffect(() => {
        if (getProjectsApi.data) {
            setBusy(false)
            setProjects(getProjectsApi.data.data)
        }
    }, [getProjectsApi.data]);
    useEffect(() => {
        if (getProjectsApi.error && getProjectsApi.error !== "") {
            console.log(getProjectsApi.error);
            setDialogText({
                title: "Error",
                text: "Error retrieving projects",
            });
            setDialogOpen(true);
        }
    }, [getProjectsApi.error]);

    const handleRadioButton = (k) => {
        setSelectedKeyword(k)
    }
    const handleSelectProject = (p) => {
        console.log(p)
    }

    const genKeywordTable = () => {
        const selectedList = conversionKeywords[selectedCountry]
        return (
            <TableContainer component={Paper} style={{ width: "1200px" }}>
                <Table size="small" aria-label="simple table">
                    <StyledTableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Keyword</TableCell>
                            <TableCell>Volume</TableCell>
                            <TableCell>CPC</TableCell>
                            <TableCell>Intent</TableCell>
                        </TableRow>
                    </StyledTableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? selectedList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : selectedList
                        ).map((entry, index) => {
                            return (
                                <TableRow key={entry.Keyword}>
                                    <TableCell >
                                        <Radio
                                            checked={selectedKeyword === entry.Keyword}
                                            onChange={() => handleRadioButton(entry.Keyword)}
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <LengthTooltip text={entry.Keyword} length={20} />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <LengthTooltip text={entry.Volume} length={30} />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <LengthTooltip text={entry['CPC (USD)']} length={20} />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <LengthTooltip text={entry?.Intent} length={20} />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    <CustomTableFooter
                        colspan={8}
                        data={selectedList}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Table>
            </TableContainer>
        )
    }

    const genCountrySelector = () => {
        return (
            <FormControl size="small" sx={{ m: 1, minWidth: 180 }}>
            <InputLabel>Country</InputLabel>

            <Select
                defaultValue=""
                labelId="country-select-label"
                id="country-select"
                value={selectedCountry}
                label="Country"
                onChange={(event) =>
                    setSelectedCountry(event.target.value)
                }
            >
                {countries.map((country) => (
                    <MenuItem key={country} value={country}>{AllCountries[country].Name}</MenuItem>
                ))}
            </Select>
            </FormControl>
        )
    }

    const handleCreateProject = () => {
        console.log('create new project')
    }
    const genCreateProjectButton = () => {
        return (
            <Button 
                onClick={handleCreateProject} 
                color="primary"
                disabled={selectedKeyword === null}>
            Create Project
          </Button>
        )
    }
    const genProjectSelector = () => {
        return (
            <FormControl size="small" sx={{ m: 1, minWidth: 180 }}>
            <InputLabel>Existing Projects</InputLabel>

            <Select
                defaultValue=""
                sx={{ minWidth: 260 }}
                labelId="project-select-label"
                id="project-select"
                label="Existing Projects"
                onChange={(event) =>
                    handleSelectProject(event.target.value)
                }
            >
                {projects.map((project) => (
                    <MenuItem value={project}>{project.name}</MenuItem>
                ))}
            </Select>
            </FormControl>
        )
    }
    return (
        <div>
            {genCountrySelector()}
            {conversionKeywords && !emptyObject(conversionKeywords) ? genKeywordTable() : null}
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Item>{genCreateProjectButton()}</Item>
                </Grid>
                <Grid item xs={8}>
                    <Item>{genProjectSelector()}</Item>
                </Grid>
            </Grid>

            <AlertDialog
                dialogOpen={dialogOpen}
                title={dialogText.title}
                text={dialogText.text}
                callback={closeDialog}
            />
            <Spinner busy={busy} />

        </div>
    )
}