import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow
} from "@mui/material";
import HoverTooltip from "../../widgets/HoverTooltip";
import LengthTooltip from "../../widgets/LengthTooltip";

import CustomTableFooter from "../../widgets/CustomTableFooter";
import { SmallTableCell, StyledTableHead } from "../../widgets/StyledWidgets";

import nlpApi from "../../../api/nlp";
import useApi from "../../../hooks/useApi";
import { textStatistics } from "../../../util/textutil";

const useStyles = makeStyles((theme) => ({
  present: {
    background: "lightgreen",
  },
  absent: {
    background: "pink",
  },
}));

export default function SEOWrapper({
  seoTargetKeyword,
  blocks,
  text,
  html,
  title,
  description,
  errorCallback,
  busyCallback,
}) {
  const classes = useStyles();

  const serpSeoApi = useApi(nlpApi.serpSeo);

  const [serpKeywords, _setSerpKeywords] = useState({});
  const [textKeywords, _setTextKeywords] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const setTextKeywords = (val) => {
    console.log(val)
    _setTextKeywords({...val})
  }
  const setSerpKeywords = (val) => {
    console.log(val)
    _setSerpKeywords({...val})
  }
  useEffect(() => {
    if (text.length > 1) {
      if (busyCallback) busyCallback(true);
      serpSeoApi.request(text, seoTargetKeyword);
    }
  }, [text]);
  useEffect(() => {
    if (serpSeoApi.data) {
      if (busyCallback) busyCallback(false);
      console.log(serpSeoApi.data.data);
      setSerpKeywords({ ...serpSeoApi.data.data['serp_by_keyword'] });
      setTextKeywords({ ...serpSeoApi.data.data['analyze_text_for_seo'] });
    }
  }, [serpSeoApi.data]);
  useEffect(() => {
    if (serpSeoApi.error && serpSeoApi.error !== "") {
      console.log(serpSeoApi.error);
      if (busyCallback) busyCallback(false);
      if (errorCallback) errorCallback("Error processing SEO");
    }
  }, [serpSeoApi.error]);

  const isInText = (entry) => {
    return text.toLowerCase().includes(entry.toLowerCase());
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const genBlocksStats = () => {
    let h1 = 0;
    let h2 = 0;
    let img = 0;
    blocks.blocks.forEach((block) => {
      if (block.type === "header") {
        if (block?.data?.level === 1) h1 = h1 + 1;
        if (block?.data?.level === 2) h2 = h2 + 1;
        if (block?.data?.level === 2) h2 = h2 + 1;
      }
      if (block.type === "header") {
        img = img + 1;
      }
    });
    return { h1: h1, h2: h2, img: img };
  };
  const generateTextStats = () => {
    let blockStats = genBlocksStats();
    let textStats = textStatistics(text);

    const minTitle = Math.min(...serpKeywords.text_stats.titles);
    const maxTitle = Math.max(...serpKeywords.text_stats.titles);
    const minDesc = Math.min(...serpKeywords.text_stats.descriptions);
    const maxDesc = Math.max(...serpKeywords.text_stats.descriptions);
    const minH1 = Math.min(...serpKeywords.text_stats.h1);
    const maxH1 = Math.max(...serpKeywords.text_stats.h1);
    const minH2 = Math.min(...serpKeywords.text_stats.h2);
    const maxH2 = Math.max(...serpKeywords.text_stats.h2);
    const minImg = Math.min(...serpKeywords.text_stats.img);
    const maxImg = Math.max(...serpKeywords.text_stats.img);
    const minWords = Math.min(...serpKeywords.text_stats.words);
    const maxWords = Math.max(...serpKeywords.text_stats.words);
    //, fontSize: "0.775rem" 
    return (
      <TableContainer component={Paper} style={{ width: "320px"}}>
        <Table size="small" aria-label="simple table">
          <StyledTableHead>
            <TableRow>
              <SmallTableCell></SmallTableCell>
              <SmallTableCell>Top Sites</SmallTableCell>
              <SmallTableCell>Text</SmallTableCell>
              <SmallTableCell>Advice</SmallTableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
          <TableRow>
              <SmallTableCell component="th" scope="row">
                Title
              </SmallTableCell>
              <SmallTableCell component="th" scope="row">
                {minTitle === maxTitle ? minTitle : "" + minTitle + " - " + maxTitle}
              </SmallTableCell>
              <SmallTableCell component="th" scope="row">
                {title ? title.length : 0}
              </SmallTableCell>
              <SmallTableCell component="th" scope="row">
                <HoverTooltip text={"30-70"} help={"30-60 characters for mobile, 30-70 characters for desktop"} />
              </SmallTableCell>
            </TableRow>

            <TableRow>
              <SmallTableCell component="th" scope="row">
                Desc
              </SmallTableCell>
              <SmallTableCell component="th" scope="row">
                {minDesc === maxDesc ? minDesc : "" + minDesc + " - " + maxDesc}
              </SmallTableCell>
              <SmallTableCell component="th" scope="row">
                {description ? description.length : 0}
              </SmallTableCell>
              <SmallTableCell component="th" scope="row">
                <HoverTooltip text={"70-155"} help={"70-130 characters for mobile, 70-155 characters for desktop"} />
              </SmallTableCell>
            </TableRow>
            <TableRow>
              <SmallTableCell component="th" scope="row">
                H1
              </SmallTableCell>
              <SmallTableCell component="th" scope="row">
                {minH1 === maxH1 ? minH1 : "" + minH1 + " - " + maxH1}
              </SmallTableCell>
              <SmallTableCell component="th" scope="row">
                {blockStats.h1}
              </SmallTableCell>
              <SmallTableCell component="th" scope="row">
                <HoverTooltip text={1} help={"Generally 1, but there is no penalty for having more"} />
              </SmallTableCell>
            </TableRow>
            <TableRow>
              <SmallTableCell component="th" scope="row">
                H2
              </SmallTableCell>
              <SmallTableCell component="th" scope="row">
                {minH2 === maxH2 ? minH2 : "" + minH2 + " - " + maxH2}
              </SmallTableCell>
              <SmallTableCell component="th" scope="row">
                {blockStats.h2}
              </SmallTableCell>
              <SmallTableCell component="th" scope="row">
                <HoverTooltip text={(textStats.totalWords / 300) | 0} help={"1 every 300 words"} />
                  {/* round to int */}
              </SmallTableCell>
            </TableRow>
            <TableRow>
              <SmallTableCell component="th" scope="row">
                Images
              </SmallTableCell>
              <SmallTableCell component="th" scope="row">
                {minImg === maxImg ? minImg : "" + minImg + " - " + maxImg}
              </SmallTableCell>
              <SmallTableCell component="th" scope="row">
                {blockStats.img}
              </SmallTableCell>
              <SmallTableCell component="th" scope="row">
              <HoverTooltip text={'-'} help={"No rule, aim for an adequate number of images to reinforce the content, display professional expertise, or establish a notable impact."} />
              </SmallTableCell>
            </TableRow>
            <TableRow>
              <SmallTableCell component="th" scope="row">
                Words
              </SmallTableCell>
              <SmallTableCell component="th" scope="row">
                {minWords} - {maxWords}
              </SmallTableCell>
              <SmallTableCell component="th" scope="row">
                {textStats.totalWords}
              </SmallTableCell>
              <SmallTableCell component="th" scope="row">
              <HoverTooltip text={'1000-2000'} help={"For blogs and articles"} />
              </SmallTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  const generateTable = () => {
    const data = serpKeywords.kw_aggregate;
    const siteCount = serpKeywords.count;
    const inTextList = textKeywords?.text;
    return (
      <TableContainer component={Paper} style={{ width: "320px" }}>
        <Table size="small" aria-label="simple table">
          <StyledTableHead>
            <TableRow>
              <SmallTableCell>Keyword</SmallTableCell>
              <SmallTableCell>Top</SmallTableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((entry, index) => {
              return (
                <TableRow
                  key={entry[0]}
                  className={
                    inTextList.includes(entry[0]) || isInText(entry[0])
                      ? classes.present
                      : classes.absent
                  }
                >
                  <SmallTableCell component="th" scope="row">
                    <LengthTooltip text={entry[0]} length={30} />
                  </SmallTableCell>
                  <SmallTableCell component="th" scope="row">
                    {entry[1].count} / {siteCount}
                  </SmallTableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <CustomTableFooter
            colspan={12}
            data={data}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Table>
      </TableContainer>
    );
  };
  return (
    <>
      {(serpKeywords?.text_stats && blocks?.blocks) ? generateTextStats() : null}
      {serpKeywords?.kw_aggregate && textKeywords?.text
        ? generateTable()
        : null}
    </>
  );
}
