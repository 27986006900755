import { removeStopwords } from 'stopword'
import { makeid } from "./util";
import textApi from "../api/gpt";

import winkNLP from 'wink-nlp'
import model from 'wink-eng-lite-web-model'

const nlp = winkNLP(model)
const its = nlp.its;

type OperationType = "more formal" | "less formal" | "longer" | "shorter" | "rephrase";

export function handleSave(config, api, block, val, operation: OperationType, hint) {
  if (config.busyCallback) config.busyCallback(true);
  let result = textApi.adjustText(val.data.text, operation, config?.context, hint, config?.context?.client);
  var innerConfig = config;
  result.then(
    (value) => {
      if (innerConfig.busyCallback) innerConfig.busyCallback(false);
      const blockToUpdate = {
        type: "text",
        data: {
          text: value.data.data,
        },
      };
      api.blocks.update(block.id, blockToUpdate.data);
      api.toolbar.close();
    },
    (reason) => {
      console.error(reason);
      if (innerConfig.busyCallback) innerConfig.busyCallback(false);
      if (innerConfig.errorCallback) innerConfig.errorCallback(reason);
    }
  );
}

export function processPlain(plain) {
  if (!plain) {
    return [];
  }
  if (typeof plain !== 'string') {
    return plain
  }
  return plain
    .split(/\r?\n/)
    .filter((text) => text.trim())
    .map((text) => {
      return {
        id: makeid(10),
        type: "paragraph",
        data: {
          text: text,
        },
      };
    });
}

function split(...args): string[] {
  return [].concat.apply([], args).join(' ').split(/\W+/)
}

export function unique(text: string): string[] {
  const minLength = 3
  const newString: string = removeStopwords(split(text.toLowerCase()))
  let uniqueArray: string[] = [...new Set(newString)]
  return uniqueArray.filter(x => x.length > minLength)
}

function sortFreqTableByCount(freqTable){
  freqTable.sort( (a, b) => b.count - a.count );
  return freqTable
}
function sortFreqTableByValue(freqTable){
  freqTable.sort( (a, b) => a.value.localeCompare(b.value) );
  return freqTable
}
export function wordsFromText(text:string){
  let freqTable = wordsFromTextByPOS(text)
  freqTable = sortFreqTableByValue(freqTable)
  let prev = null
  let mergedFreqTable = []
  for(let i=0; i<freqTable.length; i++){
    let current = freqTable[i]
    if(prev === null){
      prev = current
    } else if(prev.value === current.value){
      prev.count = prev.count + current.count
      prev.sentences = prev.sentences.concat(current.sentences)
    } else {
      mergedFreqTable.push({...prev})
      prev = current
    }
  }
  if(prev !== null)
    mergedFreqTable.push(prev)
  return sortFreqTableByCount(mergedFreqTable)
}
//NOTE - counts same word with different POS as different entries
export function wordsFromTextByPOS(text:string){
  const tokensFTByPoS = Object.create(null);
  tokensFTByPoS.NOUN = Object.create(null);
  tokensFTByPoS.ADJ = Object.create(null);
  tokensFTByPoS.VERB = Object.create(null);
  tokensFTByPoS.ADV = Object.create(null);
  tokensFTByPoS.PROPN = Object.create(null );
  let doc = nlp.readDoc( text );
  doc.tokens()
	  .each( t => {
  	  const pos = t.out(its.pos);
  	  const token = t.out(its.lemma);
  	  if (!tokensFTByPoS[pos]) return;

  	  tokensFTByPoS[pos] = tokensFTByPoS[pos] || Object.create(null);
  	  tokensFTByPoS[pos][token] = tokensFTByPoS[pos][token] || Object.create(null);
  	  tokensFTByPoS[pos][token].value = 1 + (tokensFTByPoS[pos][token].value || 0);
  	  tokensFTByPoS[pos][token].sentences = tokensFTByPoS[pos][token].sentences || new Set();
  	  tokensFTByPoS[pos][token].sentences.add( t.parentSentence().index() );
	} );

  let freqTable = [];
  for ( const pos in tokensFTByPoS ) {
    freqTable = Object.keys(tokensFTByPoS[ pos ])
  							  .map( key => (
    										{ value: key,
                         	count: tokensFTByPoS[pos][key].value, 
                         	pos: pos,
                         	sentences: Array.from( tokensFTByPoS[pos][key].sentences )
                        } ) )
								  .filter( e => e.count > 1 && e.value.length > 2 )
								  .concat( freqTable );
  }
  return sortFreqTableByCount(freqTable)
}

export function nouns(text) {

  const patterns = [
    { name: 'noun', patterns: ['[|NOUN|PROPN]'] },
    { name: 'adjectiveNounPair', patterns: [ 'ADJ NOUN' ] },
  ];
  nlp.learnCustomEntities(patterns);
  const doc = nlp.readDoc(text);
  let customEntities = doc.customEntities().out(its.detail);
  let words = customEntities.map(x => x.value)
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  // eliminate obvious plurals (ends with s, form without s in array)
  //(crude, should be improved, e.g. using lemmas)
  for (let i = 0; i < words.length; i++) {
    let chopped = words[i].substring(0, words[i].length - 1)
    words[i] = (words[i].match(/s$/g) && words.includes(chopped)) ? chopped : words[i]
  }

  if(text.includes(' AI ') || text.includes('(AI)') || text.includes(' AI.')){
    words.push('AI')
  }
  let uniquewords = [...new Set(words)].sort()
  return uniquewords
}

//TODO - brittle
export function splitByNumbers(text) {
  const regex = /^[0-9]+\.\W+/g;

  let textArray = text.split(/\r?\n/)
  textArray = textArray.map(str => str.trim()).filter(str => str.length>0)
  textArray = textArray.map(str => str.replace(regex, '')).map(str => str.replace('"', '' ));
  return textArray;
}

function _removePrefix(str, prefix){
  if (str.startsWith(prefix)) {
    str = str.slice(prefix.length);
  }
  return str
}

//roman numerals to 20 - ^(?:.(?! (?=[XVI])X?(I[XV]|V?I{0,3})$))+\S?
function _removeRomanNumerals(str) {
  const regex = /^(M{0,4})(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})([\.|\s])/i;
  return str.replace(regex, '');
}

export function cleanupPrefixed(textArray) {
  let result = textArray.map(str => str.trim())
  result = result.map(str => _removePrefix(str, '- ')).map(str => _removePrefix(str, '-'))
  result = result.map(str => _removePrefix(str, '\u2022 ')).map(str => _removePrefix(str, '\u2022'))
  result = result.map(str => _removeRomanNumerals(str)).map(str => str.trim());
  return result;
}

//count number of words and characters in each sentence
export function textStatistics(text) {
  const doc = nlp.readDoc( text );

  var sentences = doc.sentences().out()

  var regex = /[a-zA-Z0-9]/g; // only count letters and numbers
  
  let characters = sentences.map((str) => 
    str.match(regex).length
  )
  let wordsCount = sentences.map((str) => 
    str.split(' ').filter(function(word) {
     return word !== ''
    }).length
  )
  const totalWords = wordsCount.reduce((sum, num) => sum + num, 0);
  const totalCharacters = characters.reduce((sum, num) => sum + num, 0);
  return {totalWords: totalWords, totalCharacters: totalCharacters, words: wordsCount, characters: characters}
}

export const joinToQuotedText = (textArray: string[]) => {
  let tempTextArray = textArray.filter((item) => item.length > 0);
  return tempTextArray.map(d => `"${d}"`).join(',')
}