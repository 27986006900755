import { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";

import Item from "../widgets/Item";

import textApi from "../../api/gpt";
import textApi2 from "../../api/gpt2";
import useApi from "../../hooks/useApi";
import { TextGeneratorContext, UserContext } from "../../Context";

import { joinToQuotedText } from "../../util/textutil";
import {
  createSectionBlock,
  createTextBlock,
  createTitleBlock,
} from "../../util/editorutil";
import TextEditorWrapper from "./TextComponents/TextEditorWrapper";
import { augmentErrorTryAgain } from "../../util/errorutil";

const test = false;
const testTitle = "Securing Medical Data with Zero Trust Data Access";
const testSections = [
  "Introduction",
  "Overview of Securing Medical Data with Zero Trust Data Access",
  "Understanding DICOM, HIPAA and HL7",
  "Benefits of Zero Trust Data Access",
  "Implementing Zero Trust Data Access",
  "Best Practices for Securing Medical Data",
  "Conclusion",
];
const testBody = {
  status: { statusCode: 0, statusDescription: "OK", timestamp: 1677205369 },
  data: [
    "\nIntroduction\n.\nZero trust data access is a secure data access solution that increases the integrity and security of medical data. It is based on the principle of least privilege, meaning that it grants the minimum amount of access necessary to do a job. This is accomplished through identity-based authentication and authorization, where the user's identity and credentials are verified before granting access.\n\nZero trust data access solutions can help ensure that data is only accessed by authorized personnel and that unauthorized access is prevented. This is especially important for healthcare organizations, as it can help them comply with HIPAA regulations and protect patient data from malicious actors.\n\nThe authentication and authorization process is based on a set of predefined rules. These rules can be tailored to the specific needs of the organization and can be updated as needed. Additionally, the rules can be set up to periodically check the user's credentials and access rights to ensure they are still valid and up to date.\n\nIn addition to authentication and authorization, zero trust data",
    "\nOverview of Securing Medical Data with Zero Trust Data Access\n\n1. What is Zero Trust Data Access and how does it protect medical data? \nZero Trust Data Access (ZTDA) is an approach to data security that assumes all users, devices, and networks are untrusted. It implements a set of security controls and technologies to protect data from unauthorized access. ZTDA is based on the principle of least privilege, which means access is granted only on a need-to-know basis. This helps to protect medical data from unauthorized access, as it restricts access to those who need the data to perform their job duties.\n\n2.What are the benefits of using Zero Trust Data Access for medical data security? \nThere are several benefits of using ZTDA for medical data security. First, it allows organizations to have more granular control over who can access what data. This helps to prevent unauthorized access to sensitive data. Second, ZTDA can be",
    "\nWhat is DICOM and how can it help protect medical data?\n\nDigital Imaging and Communications in Medicine (DICOM) is a standard developed by the American College of Radiology (ACR) and the National Electrical Manufacturers Association (NEMA) for the interchange, storage, and retrieval of medical images and related information. It is used to facilitate communication between medical imaging devices and provides a means to transmit images and associated data between modalities, picture archiving and communication systems (PACS) and other sources. DICOM helps protect medical data by providing an open standard for the exchange and storage of medical images and related information. This standardization allows healthcare organizations to securely transfer and share medical images and data across systems, improving patient care and efficiently managing information.\n\nWhat is HIPAA and what are its implications for healthcare organizations?\n\nThe Health Insurance Portability and Accountability Act (HIPAA) is a federal law that sets national standards for the protection of health",
    "\nZero Trust Data Access (ZTDA) is a cybersecurity technology that provides a high level of security for medical data, protecting against unauthorized access, malicious actors, and data breaches. ZTDA offers better control and oversight of who has access to medical data, and can be configured to limit access only to those with a valid need to know. \n\nThe ZTDA model allows for an additional layer of authentication, which can be customized to allow access only to the areas of data that are essential to the user, thus preventing unnecessary exposure. With ZTDA, user access is granted on a more granular level - reducing the risk of data being accessed by unintended users. \n\nZTDA also works to create a secure environment for the storage and sharing of medical data, ensuring that data is not exposed to unauthorized users or malicious actors. ZTDA utilizes advanced encryption and authentication protocols, such as two-factor authentication, to verify users and protect data. This allows healthcare",
    "\nZero Trust Data Access is an approach to data security that focuses on preventing unauthorized access to data. It is based on the idea that no user or device should be trusted by default, and that all access to data should be verified and monitored. This approach is especially important when dealing with sensitive medical data, which can be highly valuable to malicious actors.\n\nTo implement Zero Trust Data Access for medical data, organizations should first identify the potential risks associated with this data. These risks can include unauthorized access by malicious actors, accidental data breaches, or data loss due to malicious software. Organizations should then establish processes and controls to mitigate these risks, such as two-factor authentication, multi-factor authentication, role-based access control, and encryption.\n\nOrganizations should also develop policies and procedures that ensure the secure access and maintenance of medical data. These policies should define how data is accessed, stored, and shared, and should require users to adhere to the security measures set in place. Additionally,",
    "\n1. Implement Multi-Factor Authentication for All User Accounts that Access Medical Data:\nMulti-factor authentication (MFA) is an authentication process where users are required to provide two or more independent credentials to access medical data. This process helps to ensure that only authorized users are able to access the data. MFA can be implemented through the use of biometrics, one-time passcodes, and other identity verification methods.\n\n2. Establish an Audit Trail for All Access to Medical Data:\nIt is important to establish an audit trail of all access to medical data in order to ensure that only authorized users are able to access the data. This audit trail should include detailed information such as the date, time, user, and action taken. This audit trail should be regularly reviewed to ensure that it is complete and accurate.\n\n3. Utilize Encryption Technology to Protect Medical Data in Transit and at Rest:\nData encryption is an important security measure that should be",
    "\nConclusion\nZero trust data access is a powerful tool for organizations to protect their sensitive medical data from unauthorized access or malicious actors. By combining traditional access control models with identity systems, zero trust data access allows organizations to secure their data and protect it from malicious actors. Zero trust data access provides a more secure data access model than traditional access control models by providing more granular access controls. This granularity allows organizations to define and restrict access to their data based on a user\u2019s identity and the specific data they are accessing. \n\nZero trust data access also allows organizations to monitor access to their data in real-time, making it easier to detect and respond to potential threats. This is done by leveraging identity-based authentication solutions such as two-factor authentication, biometrics, or device-based authentication. This ensures that only authorized users are able to access the data, and that any suspicious activity is detected quickly. \n\nImplementing a zero trust data access model can",
  ],
};

export default function TextGeneratorStep3({
  handleBack,
  handleNext,
  busyCallback,
}) {
  const [formContext, setFormContext] = useContext(TextGeneratorContext);
  const [userContext, setUserContext] = useContext(UserContext);
  const genSectionBodyApi = useApi(textApi.genSectionBody);
  const genPageTitleDescApi = useApi(textApi2.genPageTitleDesc);

  const [workDocument, setWorkDocument] = useState(null);
  const [titleDesc, setTitleDesc] = useState(null);

  useEffect(() => {
    if (test) {
      processDocument(testTitle, testSections, testBody.data);
    }
  }, []);

  useEffect(() => {
    if (!test) {
      let sectionParams = [];
      for (let i = 0; i < formContext.sections.length; i++) {
        let sectionPointsText = joinToQuotedText(formContext.sectionPoints[i]);
        let obj = {
          sectionTitle: formContext?.sections[i],
          sectionPoints: sectionPointsText,
        };
        sectionParams.push(obj);
      }
      busyCallback(true);
      let req = {
        client: userContext?.selectedClient,
        title: formContext?.title,
        type: formContext?.type,
        topic: formContext?.topic,
        tone: formContext?.tone,
        audience: formContext?.audience,
        description: formContext?.description,
        sections: sectionParams,
      };
      if (formContext?.keywords?.length > 0) {
        let keywordsText = joinToQuotedText(formContext?.keywords);
        req["keywords"] = keywordsText;
      }
      genSectionBodyApi.request(req);
      genPageTitleDescApi.request(req);
    }
  }, [formContext?.sections]);

  const processDocument = (title, sections, body) => {
    let newBlocks = [];
    let newTitleBlock = createTitleBlock(title);
    newBlocks.push(newTitleBlock);
    for (let i = 0; i < sections.length; i++) {
      let newHeaderBlock = createSectionBlock(sections[i]);
      newBlocks.push(newHeaderBlock);
      let resultArray = body[i].split("\n");
      resultArray = resultArray.filter((item) => item.length > 0);
      resultArray = resultArray.filter((item) => item !== ".");
      if (resultArray.length > 0 && resultArray[0] === sections[i])
        resultArray.shift();
      for (const element of resultArray) {
        let newTextBlock = createTextBlock(element);
        newBlocks.push(newTextBlock);
      }
    }
    setWorkDocument([...newBlocks]);
  };

  useEffect(() => {
    if (genSectionBodyApi.data) {
      busyCallback(false);
      let result = genSectionBodyApi.data.data;
      processDocument(formContext.title, formContext.sections, result);
    }
  }, [genSectionBodyApi.data]);
  useEffect(() => {
    if (genSectionBodyApi.error && genSectionBodyApi.error !== "") {
      if (
        genSectionBodyApi.error?.response?.status === 400 &&
        genSectionBodyApi.error?.response?.data?.status?.statusCode === 6
      ) {
        busyCallback(
          false,
          "Your request has been flagged by ConversionAI moderation: " +
            genSectionBodyApi.error.response.data.data
        );
      } else {
        let errorText = augmentErrorTryAgain(
          "Error generating text",
          genSectionBodyApi.error
        );
        busyCallback(false, errorText);
      }
    }
  }, [genSectionBodyApi.error]);

  useEffect(() => {
    if (genPageTitleDescApi.data) {
      //busyCallback(false);
      setTitleDesc(JSON.parse(genPageTitleDescApi.data.data));
    }
  }, [genPageTitleDescApi.data]);
  useEffect(() => {
    if (genPageTitleDescApi.error && genPageTitleDescApi.error !== "") {
      if (
        genPageTitleDescApi.error?.response?.status === 400 &&
        genPageTitleDescApi.error?.response?.data?.status?.statusCode === 6
      ) {
        busyCallback(
          false,
          "Your request has been flagged by ConversionAI moderation: " +
            genPageTitleDescApi.error.response.data.data
        );
      } else {
        let errorText = augmentErrorTryAgain(
          "Error generating title",
          genPageTitleDescApi.error
        );
        busyCallback(false, errorText);
      }
    }
  }, [genPageTitleDescApi.error]);

  const textChangeCallback = (textArray, textBlocks) => {
    console.log("textChangeCallback", textArray);
    let finalTextArray = textArray;
    if (typeof textArray === "string") finalTextArray = textArray.split("\n");

    let oldText = formContext.text.join("").replace(/\s+/g, "");
    let newText = finalTextArray.join("").replace(/\s+/g, "");
    if (formContext.text && oldText === newText) return;
    setFormContext({
      ...formContext,
      text: finalTextArray,
      textBlocks: [...textBlocks.blocks],
    });
    setWorkDocument([...textBlocks.blocks]);
  };

  const generateEditor = () => {
    return (
      <TextEditorWrapper
        id={"final-text-editor"}
        context={{...formContext, client: userContext?.selectedClient}}
        seo={formContext.seoOptimized}
        seoTitleDesc={titleDesc}
        seoTargetKeyword={formContext.seoTargetKeyword}
        docType={formContext.type}
        model={"FULL"}
        text={workDocument}
        callback={textChangeCallback}
        errorCallback={null}
        busyCallback={busyCallback}
      />
    );
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        //style={{width:"1200px"}}
      >
        <Grid item xs={8}>
          <Item>{workDocument ? generateEditor() : null}</Item>
        </Grid>
        {/* <Grid item xs={4}>
          <Item>
            <button onClick={() => handleBack(false)}>Prev</button>
          </Item>
        </Grid> */}
      </Grid>
    </>
  );
}
