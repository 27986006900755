import { useEffect, useState } from "react";

import nlpApi from "../../../api/nlp";
import useApi from "../../../hooks/useApi";
import { nouns } from "../../../util/textutil";

import EmotionPlot from "../../widgets/EmotionPlot";

const categories = [
  "fear",
  "trust",
  "joy",
  "anticipation",
  "anger",
  "disgust",
  "sadness",
  "surprise",
  "fear",
];


export default function EmotionPlotWrapper({ text, showWords, errorCallback, busyCallback }) {
  const emotionApi = useApi(nlpApi.emotion);

  const [keyword, setKeyword] = useState("");
  const [emotion, _setEmotion] = useState(null);
  const [words, setWords] = useState(null);
  
  const setEmotion = (result) => {
    let data = categories.map((cat) => result[cat]);

    _setEmotion({
      r: data,
      theta: categories,
    });
  };

  useEffect(() => {
    if (showWords && text.length > 1) {
      setWords(nouns(text))
    }
  }, [text]);
  useEffect(() => {
    if (text.length > 10) {
      if(busyCallback)
        busyCallback(true)
      emotionApi.request(text, keyword);
    }
  }, [text, keyword]);

  useEffect(() => {
    if (emotionApi.data) {
      if(busyCallback)
        busyCallback(false)

      setEmotion(emotionApi.data.data.scores);
      if(showWords && emotionApi.data.data.entities !== null)
        setWords(emotionApi.data.data.entities)
    }
  }, [emotionApi.data]);
  useEffect(() => {
    if (emotionApi.error && emotionApi.error !== "") {
      console.log(emotionApi.error);
      if(busyCallback)
        busyCallback(false)
      if(errorCallback)
        errorCallback("Error", "Error processing emotion");
    }
  }, [emotionApi.error]);

  return <EmotionPlot plotData={emotion} words={words} callback={setKeyword}/>;
}
