import { useContext, useEffect, useState } from "react";

import { List, ListItem, ListItemButton, ListItemText, TextField } from "@mui/material";

import { UserContext } from "../../Context";
import nlpApi from "../../api/nlp";
import useApi from "../../hooks/useApi";
import Spinner from "../widgets/Spinner";

import TokenStorage from "../../api/TokenStorage";
const tokenStorageService = TokenStorage.getService();

export default function ClientProfile() {
  const [userContext, setUserContext] = useContext(UserContext);
  const getProfileByClientApi = useApi(nlpApi.getProfileByClient);
  const getProfileKeywordsApi = useApi(nlpApi.getProfileKeywords);

  const [busy, setBusy] = useState(false);
  const [profile, setProfile] = useState(null);
  const [profileKeywords, setProfileKeywords] = useState(null);

  useEffect(() => {
    setBusy(true);
    getProfileByClientApi.request(userContext?.selectedClient);
    getProfileKeywordsApi.request(userContext?.selectedClient);
  }, []);
  useEffect(() => {
    if (getProfileByClientApi.data) {
      setBusy(false);
      const tempProfile = getProfileByClientApi.data.data
      setProfile(tempProfile);
    }
  }, [getProfileByClientApi.data]);
  useEffect(() => {
    if (getProfileByClientApi.error && getProfileByClientApi.error !== "") {
      setBusy(false);
      console.log(getProfileByClientApi.error)
      alert("Error retrieving profile")
    }
  }, [getProfileByClientApi.error]);
  useEffect(() => {
    if (getProfileKeywordsApi.data) {
      setBusy(false);
      const tempProfileKeywords = getProfileKeywordsApi.data.data
      setProfileKeywords(tempProfileKeywords);
    }
  }, [getProfileKeywordsApi.data]);
  useEffect(() => {
    if (getProfileKeywordsApi.error && getProfileKeywordsApi.error !== "") {
      setBusy(false);
      console.log(getProfileKeywordsApi.error)
      alert("Error retrieving profile")
    }
  }, [getProfileKeywordsApi.error]);

  const genProfile = () => {
    return (
      <>
        Profile
        <TextField
          id="companyprofile-basic"
          variant="standard"
          multiline
          rows={20}
          value={profile}
          fullWidth
        />
      </>
    )
  }
  const genProfileKeywords = () => {
    return (
      <>
        Keywords
        <List dense={true}>
          {profileKeywords.map((keyword) => {
            return (
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText>{keyword}</ListItemText>
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </>
    )
  }
  return (
    <div style={{ textAlign: "left" }}>
      <h2>Profile</h2>
      <h2>Coming Soon</h2>
      {profileKeywords ? genProfileKeywords() : null}
      {profile ? genProfile() : null}
      <Spinner busy={busy} />

    </div>
  );
}
