import { useEffect, useState } from "react";

import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import "../../../styles.css";

const testDBKeywords = true

export default function AutocompleteControlled({
  selection,
  keywords,
  label,
  callback,
  multiple,
}) {
  const [value, _setValue] = useState(selection);

  const setValue = (val) => {
    if (multiple) _setValue([...val]);
    else _setValue(val);
    callback(val);
  };
  useEffect(() => {
    if (selection === null || selection.length === 0) _setValue([]);
  }, [selection]);

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        console.log(newValue)
        if(Array.isArray(newValue)){
          setValue(newValue.map((item) => item?.keyword ? item.keyword : item))
        } else {
          setValue(newValue?.keyword ? newValue.keyword : newValue);
        }
      }}
      id="tags-filled"
      options={keywords}
      getOptionLabel={(option) => (option?.keyword ? option.keyword  : option)}
      freeSolo
      multiple={multiple}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label={multiple? "Enter or choose keywords" : "Enter or choose a keyword"}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box {...props} className={`${option.priority !== null ? "color_red" : "color_black"}`} >
          {option.keyword}
        </Box>
      )}
    />
  );
}
