import { useContext, useEffect, useState } from "react";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import LengthTooltip from "../widgets/LengthTooltip";
import CustomTableFooter from "../widgets/CustomTableFooter";
import Spinner from "../widgets/Spinner";

import { StyledTableHead } from "../widgets/StyledWidgets";

import plannerApi from "../../api/planner";
import useApi from "../../hooks/useApi";
import { UserContext } from "../../Context";
import { formatNumber } from "../../util/util";

export default function GoogleAnalytics() {
  const [userContext, setUserContext] = useContext(UserContext);

  const [busy, setBusy] = useState(false);
  const [data, setData] = useState([]);
  const [orderBy, setOrderBy] = useState("Page Views");
  const [sortOrder, setSortOrder] = useState("desc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(16);
  const getSiteAnalyticsApi = useApi(plannerApi.getSiteAnalytics);

  useEffect(() => {
    setBusy(true);
    getSiteAnalyticsApi.request(userContext?.selectedClient);
  }, []);
  useEffect(() => {
    if (getSiteAnalyticsApi.data) {
      setBusy(false);
      setData(JSON.parse(getSiteAnalyticsApi.data.data));
    }
  }, [getSiteAnalyticsApi.data]);
  useEffect(() => {
    if (getSiteAnalyticsApi.error && getSiteAnalyticsApi.error !== "") {
      setBusy(false);
      console.log(getSiteAnalyticsApi.error);
      if (
        getSiteAnalyticsApi.error?.response?.status === 404 &&
        getSiteAnalyticsApi.error?.response?.data?.status?.statusCode === 2
      ) {
        alert("Google Analytics integration not set up");
      } else {
        alert("Error getting analytics");
      }
    }
  }, [getSiteAnalyticsApi.error]);

  useEffect(() => {
    if (data.length > 0) {
      sortData(sortOrder);
    }
  }, [orderBy, sortOrder]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const sortData = (sortOrder) => {
    let tempData = [...data];
    tempData.sort(function (a, b) {
      return sortOrder === "asc"
        ? a[orderBy] < b[orderBy]
          ? 1
          : -1
        : a[orderBy] > b[orderBy]
        ? 1
        : -1;
    });
    setData([...tempData]);
  };

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <div>
      <TableContainer component={Paper} style={{ width: "1200px" }}>
        <Table size="small" aria-label="simple table">
          <StyledTableHead>
            <TableRow>
              <TableCell
                align="left"
                sortDirection={orderBy === "Title" ? sortOrder : false}
              >
                <TableSortLabel
                  active={orderBy === "Title"}
                  direction={orderBy === "Title" ? sortOrder : "asc"}
                  onClick={createSortHandler("Title")}
                >
                  Title
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="right"
                sortDirection={orderBy === "Path" ? sortOrder : false}
              >
                <TableSortLabel
                  active={orderBy === "Path"}
                  direction={orderBy === "Path" ? sortOrder : "asc"}
                  onClick={createSortHandler("Path")}
                >
                  Path
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="right"
                sortDirection={orderBy === "Page Views" ? sortOrder : false}
              >
                <TableSortLabel
                  active={orderBy === "Page Views"}
                  direction={orderBy === "Page Views" ? sortOrder : "asc"}
                  onClick={createSortHandler("Page Views")}
                >
                  Page Views
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="right"
                sortDirection={orderBy === "Users" ? sortOrder : false}
              >
                <TableSortLabel
                  active={orderBy === "Users"}
                  direction={orderBy === "Users" ? sortOrder : "asc"}
                  onClick={createSortHandler("Users")}
                >
                  Users
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="right"
                sortDirection={orderBy === "Avg Time On Page" ? sortOrder : false}
              >
                <TableSortLabel
                  active={orderBy === "Avg Time On Page"}
                  direction={orderBy === "Avg Time On Page" ? sortOrder : "asc"}
                  onClick={createSortHandler("Avg Time On Page")}
                >
                  Avg Time On Page
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((entry, index) => {
              return (
                <TableRow key={"queryanalyticstable" + entry.Keys + index}>
                  <TableCell align="right" component="th" scope="row">
                  <LengthTooltip text={entry.Title} length={40} />
                  </TableCell>
                  <TableCell align="right" component="th" scope="row">
                    <LengthTooltip text={entry.Path} length={40} />
                  </TableCell>
                  <TableCell align="right" component="th" scope="row">
                    {entry['Page Views']}
                  </TableCell>
                  <TableCell align="right" component="th" scope="row">
                    {entry.Users}
                  </TableCell>
                  <TableCell align="right" component="th" scope="row">
                    {formatNumber(entry['Avg Time On Page'], 2)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <CustomTableFooter
            colSpan={8}
            data={data}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Table>
      </TableContainer>
      <Spinner busy={busy} />
    </div>
  );
}
