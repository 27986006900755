import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from 'prop-types';

import { UserContext } from "../../Context";

import AlertDialog from "../widgets/AlertDialog";
import EmotionPlotWrapper from "./EmotionPlotWrapper";
import Item from "../widgets/Item";
import Spinner from "../widgets/Spinner";
import BorderlessItem from "../widgets/BorderlessItem";
import WordcloudWrapper from "./WordCloudWrapper";

// import { data } from "./CannedData";
import gptApi from "../../api/gpt";
import nlpApi from "../../api/nlp";
import useApi from "../../hooks/useApi";
import WordCloudTable from "./WordCloudTable";



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export default function Prospects() {
  const [userContext, setUserContext] = useContext(UserContext);
  const wordsApi = useApi(nlpApi.words);
  const findProspectsApi = useApi(gptApi.findProspects);
  const navigate = useNavigate();

  const [busy, setBusy] = useState(false);
  const [text, setText] = useState('');
  const [fullText, setFullText] = useState('');

  const [data, setData] = useState({});
  const [company, _setCompany] = useState('');
  const [prospect, _setProspect] = useState('');
  const [words, setWords] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [dialogText, setDialogText] = useState({ title: "", text: "" });
  const [dialogOpen, setDialogOpen] = useState(false);
  const closeDialog = () => {
    if(!userContext?.selectedClient){
      navigate("/agencyClients");
    }
    setDialogOpen(false);
  };

  useEffect(() => {
    if(!userContext?.selectedClient){
      setDialogText({
        title: "Error",
        text: "No Client Selected",
      });
      setDialogOpen(true);
    } else {
      setBusy(true)
      findProspectsApi.request(userContext?.selectedClient)
    }
  }, []);
  useEffect(() => {
    if (findProspectsApi.data) {
      setBusy(false);
      setData(findProspectsApi.data.data);
    }
  }, [findProspectsApi.data]);
  useEffect(() => {
    if (findProspectsApi.error && findProspectsApi.error !== "") {
      setBusy(false);
      console.log(findProspectsApi.error)
      alert("Error getting prospects")
    }
  }, [findProspectsApi.error]);


  useEffect(() => {
    if (fullText && fullText.length > 5) {
      wordsApi.request(fullText)
    }
  }, [fullText]);
  useEffect(() => {
    if (wordsApi.data) {
      setBusy(false);
      setWords(JSON.parse(wordsApi.data.data));
    }
  }, [wordsApi.data]);
  useEffect(() => {
    if (wordsApi.error && wordsApi.error !== "") {
      setBusy(false);
      console.log(wordsApi.error)
      alert("Error getting wordcloud")
    }
  }, [wordsApi.error]);

  const setCompany = (theCompany) => {
    _setCompany(theCompany)
    _setProspect('')
    setText('')
    setFullText('')
  }

  const setProspect = (theProspect) => {
    _setProspect(theProspect)
    setText('')
    let tempFullText = theProspect.about
    for (let i = 0; i < theProspect.posts.length; i++) {
      tempFullText = tempFullText + '. ' + theProspect.posts[i]
    }
    setFullText(tempFullText)
  }

  const handleError = (title, body) => {
    setBusy(false);
    setDialogText({ title: title, text: body });
    setDialogOpen(true);
  };

  const genCompanySelector = () => {
    return (
      <BorderlessItem>
        <FormControl size="small" sx={{ m: 1, minWidth: 220 }}>
          <InputLabel>Company</InputLabel>
          <Select
            labelId="gen-type-select-label"
            id="gen-type-select"
            value={company}
            label="Company"
            onChange={(event) => setCompany(event.target.value)}
          >
            {Object.keys(data).map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </BorderlessItem>
    )
  }
  const genProspectSelector = () => {
    let prospects = data[company]['employees']
    return (
      <BorderlessItem>
        <FormControl size="small" sx={{ m: 1, minWidth: 220 }}>
          <InputLabel>Prospects</InputLabel>
          <Select
            labelId="gen-prospect-select-label"
            id="gen-prospect-select"
            value={prospect}
            label="Prospects"
            onChange={(event) => setProspect(event.target.value)}
          >
            {prospects.map((item) => (
              <MenuItem value={item}>{item.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </BorderlessItem>
    )
  }

  const trimString = (text, length) => {
    return text && text.length > length ?
      text.slice(0, length - 2) + "..."
      : text
  }
  const genPostSelector = () => {
    let posts = []
    posts.push({ name: "About", "text": prospect['about'] })
    let allPostText = ''
    for (let i = 0; i < prospect['posts'].length; i++) {
      allPostText = allPostText + ' ' + prospect['posts'][i]
    }
    posts.push({ name: "All Posts", "text": allPostText })
    for (let i = 0; i < prospect['posts'].length; i++) {
      //posts.push({ name: "Post " + (i + 1) , text: prospect['posts'][i] })
      posts.push({ name: "Post: " + trimString(prospect['posts'][i], 20), text: prospect['posts'][i] })
    }
    return (
      <BorderlessItem>
        <FormControl size="small" sx={{ m: 1, minWidth: 220 }}>
          <InputLabel>Posts</InputLabel>
          <Select
            labelId="gen-posts-select-label"
            id="gen-posts-select"
            label="Posts"
            onChange={(event) => setText(event.target.value)}
          >
            {posts.map((item) => (
              <MenuItem value={item.text}>{item.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </BorderlessItem>
    )
  }

  const genText = () => {
    return (
      <TextField
        id="email_description"
        multiline
        rows={10}
        label="Text"
        variant="standard"
        value={text}
        disabled={true}
        onChange={(event) => setText(event.target.value)}
        fullWidth
      />
    );
  };

  const genEmotion = () => {
    if (text && text.length > 10)
      return (
        <EmotionPlotWrapper
          text={text}
          showWords={true}
          errorCallback={handleError}
          busyCallback={setBusy}
        />
      );
    else return null;
  };

  const genWC = () => {
    if (fullText && fullText.length > 10)
      return (
        <WordcloudWrapper words={words} />
      );
    else return null;
  };
  const genWCTable = () => {
    if (fullText && fullText.length > 10)
      return (
        <WordCloudTable words={words} keywordSelectCallback={setSelectedKeywords}/>
      );
    else return null;
  };

  const genTabs = () => {
    return (
      <>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="wordcloud-header"
          >
            <Typography>Word Cloud</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Box sx={{ width: '100%' }}>
                {genWC()}
              </Box>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="wcounts-header"
          >
            <Typography>Word Counts</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Box sx={{ width: '100%' }}>
                {genWCTable()}
              </Box>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </>
    )
  }
  const handleEmail = () => {
    console.log('handle email')
    console.log(prospect)
    console.log(company)
    console.log(selectedKeywords)
    navigate("/emailGenerator", {
      state: {
        to: prospect?.name,
        toCompany: company,
        keywords: selectedKeywords
      }
    })
  }
  const genComposeButtons = () => {
    return (
      <Button
        onClick={() => {
          handleEmail()
        }}
      >Email</Button>
    )
}
return (
  <div style={{ textAlign: "left" }}>
    <h2>Affinity Profiles</h2>
    <Box component="form" noValidate autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Item>{genCompanySelector()}</Item>
        </Grid>
        <Grid item xs={4}>
          <Item>{company ? genProspectSelector() : null}</Item>
        </Grid>
        <Grid item xs={4}>
          <Item>{prospect ? genPostSelector() : null}</Item>
        </Grid>
        <Grid item xs={12}>
          <Item>{genTabs()}</Item>
        </Grid>

        <Grid item xs={6}>
          <Item>{genText()}</Item>
        </Grid>
        <Grid item xs={6}>
          <Item>{genEmotion()}</Item>
        </Grid>
        <Grid item xs={6}>
          <Item>{genComposeButtons()}</Item>
        </Grid>
      </Grid>
    </Box>
    <AlertDialog
      dialogOpen={dialogOpen}
      title={dialogText.title}
      text={dialogText.text}
      callback={closeDialog}
    />
    <Spinner busy={busy} />
  </div>
);
}
