import { useContext, useEffect, useState } from "react";

import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";

import { UserContext } from "../../Context";
import textApi from "../../api/gpt";
import useApi from "../../hooks/useApi";
import Spinner from "../widgets/Spinner";

import TokenStorage from "../../api/TokenStorage";
const tokenStorageService = TokenStorage.getService();

export default function ClientVoices() {
  const [userContext, setUserContext] = useContext(UserContext);
  const getVoicesApi = useApi(textApi.getVoices);

  const [busy, setBusy] = useState(false);
  const [voices, setVoices] = useState(null);


  useEffect(() => {
    setBusy(true);
    getVoicesApi.request(userContext?.selectedClient);
  }, []);
  useEffect(() => {
    if (getVoicesApi.data) {
      setBusy(false);
      const tempVoice = getVoicesApi.data.data
      setVoices(tempVoice);
    }
  }, [getVoicesApi.data]);
  useEffect(() => {
    if (getVoicesApi.error && getVoicesApi.error !== "") {
      setBusy(false);
      console.log(getVoicesApi.error)
      alert("Error retrieving voices")
    }
  }, [getVoicesApi.error]);

  const genVoices = () => {
    return (
      <List>
        {voices.map((voice) => {
          return (
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText>{voice}</ListItemText>
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
    )
  }
  return (
    <div style={{ textAlign: "left" }}>
      <h2>Voices</h2>
      <h2>Coming Soon</h2>
      {voices ? genVoices() : null}
      <Spinner busy={busy} />

    </div>
  );
}
