export const toneOptions = [
    { name: "casual" },
    { name: "enthousiastic" },
    { name: "formal" },
    { name: "neutral" },
    { name: "persuasive" },
    { name: "professional" },
  ];
export const styleOptions = [
    { name: "academic" },
    { name: "business" },
    { name: "clear" },
    { name: "conversational" },
    { name: "creative" },
    { name: "legal" },
    { name: "medical" },
    { name: "punchy" },
  ];
export const generatorTypes  = [
    { name: "Blog", value: "blog" },
    { name: "Article", value: "article" },
    { name: "News Release", value: "news" },
    { name: "Web Page", value: "website" },
  ];
  