import { useEffect, useState } from "react";
import Plot from "react-plotly.js";

import {Autocomplete, IconButton, TextField} from '@mui/material';

import "./RefreshIcon.css"
import { keyboard } from "@testing-library/user-event/dist/keyboard";

const layout = {
  polar: {
    radialaxis: {
      visible: true,
    },
  },
  width: 320,
  height: 320,
  showlegend: false,
  margin: {
    l: 60,
    r: 55,
    b: 10,
    t: 10,
    pad: 4,
  },
};

const config = {
  displayModeBar: false,
};

export default function EmotionPlot({ plotData, words, callback }) {
  const [radarPlotData, setRadarPlotData] = useState({
    type: "scatterpolar",
    r: [0, 0, 0, 0, 0, 0, 0, 0],
    theta: [
      "fear",
      "trust",
      "joy",
      "anticipation",
      "anger",
      "disgust",
      "sadness",
      "surprise",
    ],
    fill: "toself",
  });
  const [value, _setValue] = useState('');
  const setValue = (val) => {
    _setValue(val);
    if (callback) callback(val);
  };

  useEffect(() => {
    if (words && !words.includes(value)){
      setValue('')
    }
  }, [words]);

  useEffect(() => {
    if (plotData && plotData.r && plotData.theta){
      setRadarPlotData({
        ...radarPlotData,
        r: [...plotData.r],
        theta: [...plotData.theta],
      });
    }
  }, [plotData]);

  return (
    <div className="App">
      <Plot data={[radarPlotData]} layout={layout} config={config} />
      {words && callback ? (
        <>
          <Autocomplete
            disablePortal
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            id="words-autocomplete"
            options={words}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Words" />}
          />
          {/* <IconTextField callback={callback} /> */}
        </>
      ) : null}
    </div>
  );
}
