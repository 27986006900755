import { generatorApiInstance } from "./GPTApiInstance";

const genText = (selection, audience, tone, keywords, topic, description, client) =>
  generatorApiInstance.post("/gentext/" + client, {
    selection: selection,
    audience: audience,
    tone: tone,
    keywords: keywords,
    topic: topic,
    description: description,
  });
const adjustText = (text, operation, context, hint, client) =>
  generatorApiInstance.post("/adjusttext", {
    text: text,
    operation: operation,
    context: context,
    hint: hint,
    client: client,
  });
const rephraseWithProfile = (text, hint, voice, tone, style, useProfile, client) =>
  generatorApiInstance.post("/rephrasewithprofile", {
    text: text,
    hint: hint,
    voice: voice,
    tone: tone,
    style: style,
    use_profile: useProfile,
    client: client,
  });

const genEmail = (
  sender,
  sender_company,
  recipient,
  recipient_company,
  subject,
  description,
  tone,
  style,
  client
) =>
  generatorApiInstance.post("/genemail", {
    client: client,
    sender: sender,
    sender_company: sender_company,
    recipient: recipient,
    recipient_company: recipient_company,
    subject: subject,
    description: description,
    tone: tone,
    style: style,
  });

const genIdea = (params) => generatorApiInstance.post("/genIdea", params);
const genSections = (params) =>
  generatorApiInstance.post("/genSections", params);
const genSectionPoints = (params) =>
  generatorApiInstance.post("/genSectionPoints", params);
const genSectionBody = (params) =>
  generatorApiInstance.post("/genSectionBody", params);
const genBySample = (params) =>
  generatorApiInstance.post("/genBySample", params);
const topics = (text, client) => generatorApiInstance.post("/topics", { text: text, client: client });

const genSupportingKeywords = (keyword, client) => generatorApiInstance.get("/genSupportingKeywords/" + client + "/" + keyword);
const getVoices = (client) => generatorApiInstance.get("/getvoices/" + client);

const findProspects = (client) =>
  generatorApiInstance.post("/find_prospect", {
    client: client,
  });


const expose = {
  adjustText,
  findProspects,
  genText,
  genEmail,
  genIdea,
  genSections,
  genSectionPoints,
  genSectionBody,
  genSupportingKeywords,
  genBySample,
  getVoices,
  rephraseWithProfile,
  topics,
};
export default expose;
