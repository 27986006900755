import React from "react";
import { IconButton, Tooltip } from "@mui/material";

import HelpIcon from "@mui/icons-material/Help";


export default function HelpTooltip({ text, label = null, size = null}) {
  let iconSize = 'medium'
  if(size && ['inherit', 'large', 'medium', 'small'].includes(size))
    iconSize = size
  return (
    <Tooltip title={<React.Fragment>{text}</React.Fragment>} placement="right-start">
      <IconButton>
        <HelpIcon color='primary' fontSize={iconSize} />
        {label ? <div>{label}</div> : null}
      </IconButton>
    </Tooltip>
  );
}