import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Select,
  FormControlLabel,
  Checkbox,

} from "@mui/material";

import { UserContext } from "../../Context";
import textApi from "../../api/gpt";
import useApi from "../../hooks/useApi";

import AlertDialog from "../widgets/AlertDialog";
import Spinner from "../widgets/Spinner";

import {generatorTypes, styleOptions, toneOptions} from './Options'
import TokenStorage from "../../api/TokenStorage";
const tokenStorageService = TokenStorage.getService();


export default function RephraseTool() {
  const [userContext, setUserContext] = useContext(UserContext);
  const navigate = useNavigate();

  const [busy, setBusy] = useState(false);
  const [clients, setClients] = useState([]);
  const [hint, setHint] = useState(null);
  const [tone, setTone] = useState("professional");
  const [style, setStyle] = useState("business");
  const [generatedEmail, _setGeneratedEmail] = useState("");
  const [useProfile, setUseProfile] = useState(false);
  const [voices, setVoices] = useState(null);
  const [selectedVoice, setSelectedVoice] = useState('');
  const [selectedType, setSelectedType] = useState('blog');
  const [inputText, setInputText] = useState("");
  const [updatedText, setUpdatedText] = useState("");
  const [dialogText, setDialogText] = useState({ title: "", text: "" });
  const [dialogOpen, setDialogOpen] = useState(false);
  const closeDialog = () => {
    if(!userContext?.selectedClient){
      navigate("/agencyClients");
    }
    setDialogOpen(false);
  };

  const getVoicesApi = useApi(textApi.getVoices);
  const rephraseWithProfileApi = useApi(textApi.rephraseWithProfile);

  const helpText = {
    instruction: "Provide rephrasing guidance",
    type: "Select or Enter a Document Type",
    tone: "Select or Enter a Tone for the Email",
    style: "Select or Enter a Style for the Email",
    voice: "Select a Corporate Voice for the Email",
  };

  useEffect(() => {
    setBusy(true);
    getVoicesApi.request(userContext?.selectedClient);
  }, []);
  useEffect(() => {
    if (getVoicesApi.data) {
      setBusy(false);
      const tempVoice = getVoicesApi.data.data
      setVoices(tempVoice);
      if(tempVoice?.length > 0){
        setSelectedVoice(tempVoice[0])
      }
    }
  }, [getVoicesApi.data]);
  useEffect(() => {
    if (getVoicesApi.error && getVoicesApi.error !== "") {
      console.log(getVoicesApi.error)
      alert("Error retrieving voices")
    }
  }, [getVoicesApi.error]);

  const doRephrase = () => {
    setBusy(true);
    rephraseWithProfileApi.request(inputText, hint, selectedVoice, tone, style, useProfile, userContext?.selectedClient)
  
  }
  useEffect(() => {
    if (rephraseWithProfileApi.data) {
      setBusy(false);
      setUpdatedText(rephraseWithProfileApi.data.data);
    }
  }, [rephraseWithProfileApi.data]);
  useEffect(() => {
    if (rephraseWithProfileApi.error && rephraseWithProfileApi.error !== "") {
      setBusy(false);
      setDialogText({ title: "Error", text: "Error rephrasing text" });
      setDialogOpen(true);
    }
  }, [rephraseWithProfileApi.error]);

  const genToneOrVoice = () => {
    if (voices && voices.length > 0)
      return (
        <>
          <FormControl size="small" sx={{ m: 1, minWidth: 180 }}>
            <InputLabel>Voice</InputLabel>
            <Select
              id="voices-select"
              value={selectedVoice}
              label="Voices"
              onChange={(event) => setSelectedVoice(event.target.value)}
            >
              {voices.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>

          </FormControl>
        </>
      )
    else
      return (
        <>
          <Stack spacing={2} direction="row">
            <div style={{ width: "100%" }}>
              <Autocomplete
                id="tone-select"
                freeSolo
                options={toneOptions.map((option) => option.name)}
                renderInput={(params) => <TextField {...params} label="Tone" />}
                value={tone}
                onChange={(event, newValue) => {
                  setTone(newValue);
                }}
              />
            </div>
          </Stack>
          <Stack spacing={2} direction="row">
            <div style={{ width: "100%" }}>
              <Autocomplete
                id="style-select"
                freeSolo
                options={styleOptions.map((option) => option.name)}
                renderInput={(params) => <TextField {...params} label="Style" />}
                value={style}
                onChange={(event, newValue) => {
                  setStyle(newValue);
                }}
              />
            </div>
          </Stack>
        </>
      )
  }

  const genTypeSelector = () => {
    return (
      <FormControl size="small" sx={{ m: 1, minWidth: 180 }}>
        <InputLabel>Type</InputLabel>
        <Select
          labelId="rephrase-type-select-label"
          id="rephrase-type-select"
          value={selectedType}
          label="Type"
          onChange={(event) => setSelectedType(event.target.value)}
        >
          {generatorTypes.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
const handleUseProfile = () => {
    setUseProfile((current) => !current);
}
  const genProfileCheckbox = () => {
    return (
      <FormControl size="small" sx={{ m: 1, minWidth: 180 }}>
        <FormControlLabel control={
                              <Checkbox
                              checked={useProfile}
                              onChange={handleUseProfile}
                              name="useProfileCheckbox"
                              color="primary"
                            />
      
        } label="Use Company Profile" />
      </FormControl>
    )
  }

  const genHint = () => {
    return (
      <TextField
        id="rephrasehint-basic"
        label="Instructions (optional)"
        variant="standard"
        multiline
        rows={2}
        value={hint}
        onChange={(event) => setHint(event.target.value)}
        fullWidth
      />
    )
  }
  const genInputText = () => {
    return (
      <TextField
        id="rephraseinput-basic"
        label="Input"
        variant="standard"
        multiline
        rows={10}
        value={inputText}
        onChange={(event) => setInputText(event.target.value)}
        fullWidth
      />
    )
  }
  const genOutputText = () => {
    return (
      <TextField
        id="rephraseoutput-basic"
        label="Rephrased"
        variant="standard"
        multiline
        rows={10}
        value={updatedText}
        fullWidth
      />
    )
  }
  return (
    <div style={{ textAlign: "left" }}>
      <h2>Rephrase</h2>
      {genToneOrVoice()}
      {genTypeSelector()}
      {genProfileCheckbox()}
      {genHint()}
      {genInputText()}
      <Button
            variant="contained"
            onClick={doRephrase}
            disabled={inputText.length === 0}
          >
            Rephrase
          </Button>

      {genOutputText()}
      <Spinner busy={busy} />
      <AlertDialog
        dialogOpen={dialogOpen}
        title={dialogText.title}
        text={dialogText.text}
        callback={closeDialog}
      />
    </div>
  );
}

// let result = textApi.adjustText(val.data.text, operation, config?.context, hint, config?.context?.client);
/*
{
  "text": "The meeting is scheduled for [date] at [time] and will be hosted at our office located at [address]. During the meeting, we will be covering important topics such as recent market trends, upcoming product releases, and our strategies for driving business growth.",
  "operation": "rephrase",
  "context": {
    "type": "email",
    "tone": "professional",
    "style": "business",
    "title": "meeting next week",
    "description": "invite to sales meeting at our office",
    "client": "515d891a-807d-11ed-bd49-0242c0a84003"
  },
  "hint": "sound like Donald Duck",
  "client": "515d891a-807d-11ed-bd49-0242c0a84003"
}
*/