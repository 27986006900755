import { generatorApiInstance } from "./GPTApiInstance2";


const genIdea = (params) => generatorApiInstance.post("/genIdea", params);
const genPlannerIdea = (params) => generatorApiInstance.post("/genPlannerIdea", params);

const genSections = (params) =>
  generatorApiInstance.post("/genSections", params);
const genSectionPoints = (params) =>
  generatorApiInstance.post("/genSectionPoints", params);
const genSectionBody = (params) =>
  generatorApiInstance.post("/genSectionBody", params);
const genPageTitleDesc = (params) =>
  generatorApiInstance.post("/genPageTitleDesc", params);

const genEmailV2 = (
    sender,
    sender_company,
    recipient,
    recipient_company,
    subject,
    description,
    tone,
    style,
    client,
    voice,
    useProfile,
    keywords,
    ) =>
    generatorApiInstance.post("/genemail", {
      client: client,
      sender: sender,
      sender_company: sender_company,
      recipient: recipient,
      recipient_company: recipient_company,
      subject: subject,
      description: description,
      tone: tone,
      style: style,
      voice: voice,
      use_profile: useProfile,
      keywords: keywords,
    });
  
const expose = {
  genEmailV2,
  genIdea,
  genPlannerIdea,
  genSections,
  genSectionPoints,
  genSectionBody,
  genPageTitleDesc,
};
export default expose;
